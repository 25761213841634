import React, {useState} from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';

const VerifyAccount = (props) => {
  const { email, token } = useParams();
  const history = useHistory();
  const { register, handleSubmit, formState: {errors} } = useForm();
  const [verifyRequest, setVerifyRequest] = useState({email: email, token: token});

  const onVerifyClick = async (data) => {
    setVerifyRequest(data);
    try {
      const url = `${cnst.apiBaseUrl()}accounts/verify-email`;
      const result = await trackPromise(axios.post(url, data));
      if (result.data) {
        Swal.fire({
          icon: 'success',
          title: 'DingoDeals',
          text: 'Thank you for verifying your account. You can now login.',
          footer: 'Click Ok to be redirected to the login page.',
          confirmButtonText: `Ok`,
        }).then((res) => {
          // Navigate to verification page.
          history.push("/signin");
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: cnst.applicationName,
        text: 'An error has occurred verifying your account. Please ensure that you entered the correct email and token.'
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="card-header text-center">
          <p className="h3 my-2">Verify Account</p>
          <p className="h6 mb-2">Enter the verification code you received</p>
        </div>

        <div className="card-body">
          
          <form className="text-center border border-light p-5" 
            onSubmit={handleSubmit(onVerifyClick)} 
            autoComplete="off">
            
            <div className="mb-3 row">
              <label htmlFor="inputEmail" 
                className="col-md-4 col-form-label">
                Email
              </label>
              <div className="col-md-8">
                <input type="text" 
                  id="inputEmail"
                  name="email" 
                  className="form-control" 
                  placeholder="Email"
                  defaultValue={verifyRequest.email}
                  {...register('email', {required: true, 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email format."
                  }})} 
                />
              </div>
              
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.email?.type === "required" && <span className="text-danger fs-6 fw-bold">Email is required</span>}
                {errors.email?.type === "pattern" && <span className="text-danger fs-6 fw-bold">{errors.email.message}</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputVerificationToken" 
                className="col-md-4 col-form-label">
                Verification Code
              </label>
              <div className="col-md-8">
                <input type="text" 
                  id="inputVerificationToken"
                  name="token" 
                  className="form-control" 
                  placeholder="Verification Code"
                  defaultValue={verifyRequest.token}
                  {...register('token',
                    {
                      required: true,
                      pattern: {
                        value: /^\d+$/,
                        message: "Token can only be numeric."
                      }
                    })
                  } 
                />
              </div>
              
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.token?.type === "required" && <span className="text-danger fs-6 fw-bold">Token is required</span>}
                {errors.token?.type === "pattern" && <span className="text-danger fs-6 fw-bold">{errors.token.message}</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-md-4 col-form-label"></label>
              <button id="btnSubmit" 
                className="btn btn-primary btn-lg col-md-3 mx-2" 
                type="submit" >
                Submit
              </button>
            </div>

          </form>
        </div>

        <div className="card-footer text-muted">
          <p className="text-center small">
            Did not get token? Click here to <Link to="/sendverification">Resend</Link>
          </p>
          
        </div>


      </div>
    </div>
  )
}

export default VerifyAccount;