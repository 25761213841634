// https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
const inMemoryTokens = () => {
  let inMemoryToken = null;
  let headers = {
    headers: {
      "Authorization" : null
    }
  };
  let uploadFileHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': null
    }
  }
  let applicationUser = null;
  let applicationUserId = null;

  const getToken = () => inMemoryToken;

  const setToken = (token) => {
    inMemoryToken = token;
    headers.headers.Authorization = `Bearer ${token}`
    uploadFileHeaders.headers.Authorization = `Bearer ${token}`
    return true;
  };

  const eraseToken = () => {
    inMemoryToken = null;
    return true;
  };

  const getHeaders = () => headers;

  const getUploadFileHeaders = () => uploadFileHeaders;

  const setUser = (user) => {
    applicationUser = user;
    return true;
  };

  const getUser = () => applicationUser;

  const setUserId = (userId) => {
    applicationUserId = userId;
    return true;
  }

  const getUserId = () => applicationUserId;

  return {
    getToken,
    setToken,
    eraseToken,
    getHeaders,
    getUploadFileHeaders,
    setUser,
    getUser,
    setUserId,
    getUserId
  }
};

export default inMemoryTokens();
