import React, {useState} from 'react';

const About = (props) => {
  return (
    <div >
      About Us
    </div>
  )
}

export default About;