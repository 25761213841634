import React, { useState } from 'react';

const HowItWorks = (props) => {
    const [featuredDeals, setFeaturedDeals] = useState([]);

    return (
        <div>
            How It Works
        </div>
    )
}

export default HowItWorks;