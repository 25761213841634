import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, NavLink } from "react-router-dom";
import inMemoryTokens from '../../helpers/inMemoryTokens';
import './styles.css';
import dingoLogo from '../../assets/images/logo.png';


const Navigation = (props) => {
  const history = useHistory();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const currUser = inMemoryTokens.getUser();
  const isSignedIn = (currUser !== null); 
  const location = useLocation();
  const [showSignUp, setShowSignUp] = useState(true);
  const [showSignIn, setShowSignIn] = useState(true);

  useEffect(() => {
    setShowSignUp(location.pathname !== '/signup');
    setShowSignIn(location.pathname !== '/signin');
  }, [location.pathname]);
  
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const onSignoutClick = () => {
    inMemoryTokens.setUser(null);
    history.push("/signin");
  };

  return (
    <header className="sticky-top">
      <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
        <div className="container-fluid">

          <a className="navbar-brand brw-navbar-brand" href="/" target="_blank" rel="noopener noreferrer">
            <img src={dingoLogo} width="48" alt="Dingo Deals" />
          </a>

          <button className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse"
            aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
            onClick={handleNavCollapse}>
            <i className="fa fa-bars"></i>
          </button>
                  
          <div id="navbarCollapse" className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" onClick={handleNavCollapse}>
              <li className="nav-item">
                <NavLink exact={true} className="navbar-brand" activeClassName='active' to={'/'}>Home</NavLink>
              </li>
              {
                (isSignedIn)
                ?
                <>
                  <li className="nav-item">
                    <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/admin'>Admin</NavLink>
                  </li>
                </>
                :
                <>
                  <li className="nav-item">
                    <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/about'>About</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/featured'>Featured</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/howitworks'>How It Works</NavLink>
                  </li>
                </>

              }              

            </ul>

            <ul className="navbar-nav" onClick={handleNavCollapse}>
              {
                (isSignedIn)
                ?
                <>
                  <li className="nav-item text-light">
                    <button id="btnSubmit" 
                      className="btn btn-primary btn-block mx-2"
                      onClick={onSignoutClick} >
                      Logout
                    </button>
                  </li>
                </>
                :
                <>
                  <li>
                    {
                      (showSignUp) ? <NavLink exact={true} className="btn btn-outline-light align-self-center mx-2 my-1" to='/signup'>Register your Business</NavLink> : null
                    }

                    {
                      (showSignIn) ? <NavLink exact={true} className="btn btn-outline-light align-self-center mx-2 my-1" to='/signin'>Sign In to your Account</NavLink> : null
                    }
                  </li>
                </>
              }
              
              
            </ul>
          </div>
          
        </div>
      </nav>
    </header>
  )
}

export default Navigation;



































// import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import './styles.css';

// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor (props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }

//   toggleNavbar () {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render () {
//     return (
//       <header>
//         <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
//           <Container>
//             <NavbarBrand tag={Link} to="/">DingoDeals</NavbarBrand>
//             <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//             <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//               <ul className="navbar-nav flex-grow">
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
//                 </NavItem>
//               </ul>
//             </Collapse>
//           </Container>
//         </Navbar>
//       </header>
//     );
//   }
// }


// <header className="sticky-top">
    //   <nav className="navbar brw-navbar navbar-expand-lg navbar-light">
    //     <a className="navbar-brand brw-navbar-brand" href="https://deno.org" target="_blank" rel="noopener noreferrer">
    //       <img src={dingologo} width="150" alt="Deno" />
    //     </a>

    //     <button className="navbar-toggler" type="button" data-toggle="collapse"
    //       data-target="#navbarCollapse" aria-controls="navbarCollapse"
    //       aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
    //       onClick={handleNavCollapse}>
    //       <i className="fa fa-bars"></i>
    //     </button>
              
    //     <div id="navbarCollapse" className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}>
    //       <ul className="navbar-nav mr-auto">
    //         <li className="nav-item">
    //             <NavLink exact={true} className="navbar-brand" activeClassName='active' to={'/'}>Home</NavLink>
    //         </li>
    //         <li className="nav-item">
    //             <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/inventory'>Inventory</NavLink>
    //         </li>
    //         <li className="nav-item">
    //             <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/my'>Checkouts</NavLink>
    //         </li>
    //         <li className="nav-item">
    //             <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/history'>History</NavLink>
    //         </li>
    //         <li className="nav-item">
    //             <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/help'>Help</NavLink>
    //         </li>
    //         {(isAdmin()) &&
    //             <li className="nav-item">
    //                 <NavLink exact={true} className="navbar-brand" activeClassName='active' to='/admin'>Admin</NavLink>
    //             </li>
    //         }
    //       </ul>

    //         <ul className="navbar-nav">
    //             <li className="nav-item text-light">
    //                 Hello, 
    //                 <span className="font-weight-bold pl-1">
    //                     {fullName}
    //                 </span>
    //             </li>
    //         </ul>
    //     </div>
    //   </nav>
    // </header>