import React, {useState} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onForgotPasswordSubmit = async (data) => {
    setEmail(data);

    try {
      const url = `${cnst.apiBaseUrl()}accounts/forgot-password`;
      const result = await trackPromise(axios.post(url, data));
      const resultData = result.data;
      if (resultData) {
        Swal.fire({
          icon: 'success',
          title: 'DingoDeals',
          text: resultData.message,
          footer: 'Kindly check your email to reset your password.',
          confirmButtonText: `Ok`,
        }).then((res) => {
          // Clear the form.
          setEmail('');
          history.push("/signin");
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: cnst.applicationName,
        text: 'Error occurred processing the request. Please try again.'
      });
    };
  }

  return (
    <div className="container mt-5">
      <div className="card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="card-header text-center">
          <p className="h3 my-2">Forgot Password</p>
          <p className="h6 mb-2">Enter the email you used to register with DingoDeals.</p>
        </div>

        <div className="card-body">

          <form className="text-center border border-light p-5" 
            onSubmit={handleSubmit(onForgotPasswordSubmit)} 
            autoComplete="off">
            
            <div className="mb-3 row">
              <label htmlFor="inputEmail" 
                className="col-md-4 col-form-label">
                Email
              </label>
              <div className="col-md-8">
                <input type="text" 
                  id="inputEmail"
                  name="email" 
                  className="form-control" 
                  placeholder="Email"
                  defaultValue={email}
                  {...register('email', {required: true, 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email format."
                  }})}
                />
              </div>
              
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.email?.type === "required" && <span className="text-danger fs-6 fw-bold">Email is required</span>}
                {errors.email?.type === "pattern" && <span className="text-danger fs-6 fw-bold">Invalid email format</span>}
              </div>

            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-md-4 col-form-label"></label>
              <button id="btnSubmit" 
                className="btn btn-primary btn-lg col-xl-3 col-lg-4 col-md-6 mx-2" 
                type="submit" >
                Submit
              </button>
            </div>
          
          </form>
        </div>

        <div className="card-footer text-muted">
          <p className="text-center small">
            Already have an account? <Link to="/signin">Sign In</Link>
          </p>
          <p className="text-center small">
            Don't have an account? <Link to="/signup">Click here to Register</Link>
          </p>
        </div>
      </div>
    </div>
  )
};

export default ForgotPassword;