import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import inMemoryTokens from './inMemoryTokens';

const SecuredRoute = ({ component: Component, currentUser, ...rest }) => {
  const loggedInUser = inMemoryTokens.getUser()
  const isLoggedIn = () => {

    return (loggedInUser !== null);
  }

return (
    <Route {...rest} render={
        props => {
            if (isLoggedIn()) {
                return <Component {...rest} {...props} />
            } else {
                return <Redirect to={
                    {
                        pathname: '/unauthorized',
                        state: {
                            from: props.location
                        }
                    }
                } />
            }
        }

    } />
  )
}

export default SecuredRoute;

