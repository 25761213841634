import React, {useState} from 'react';
import { Switch, Route } from 'react-router';
import Layout from './components/Layout/index';

import Featured from './components/Featured';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import HowItWorks from './components/HowItWorks';
import ResetPassword from './components/ResetPassword';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import VerifyAccount from './components/VerifyAccount';
import SendVerification from './components/SendVerification';
import Admin from './components/Admin';
import Unauthorized from './components/Unauthorized';
import About from './components/About';

import SecuredRoute from './helpers/securedRoute';
// import inMemoryTokens from './helpers/inMemoryTokens';

// import './assets/css/style.css';

const App = () => {
  // const token = inMemoryTokens.getToken();
  const [currentUser, setCurrentUser] = useState('Angelo');

  return (
    <Layout currentUser={currentUser} setCurrentUser={setCurrentUser} >
      <Switch currentUser={currentUser}>
        <Route exact path="/" component={Home} currentUser={currentUser} />
        <Route exact path="/featured" component={Featured} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/howitworks" component={HowItWorks} />
        <Route exact path="/resetpassword/:resettoken" component={ResetPassword} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signin" component={SignIn}  />
        <Route exact path="/verifyaccount" component={VerifyAccount} />
        <Route exact path="/verifyaccount/:email/:token" component={VerifyAccount} />
        <Route exact path="/sendverification" component={SendVerification} />
        <Route exact path="/about" component={About} />
        
        <SecuredRoute exact path="/admin" component={Admin} currentUser={currentUser} />
        <Route exact path="/unauthorized" component={Unauthorized} />
      </Switch>
    </Layout>
  )
}

export default App;