import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import inMemoryTokens from '../../helpers/inMemoryTokens';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h, html } from "gridjs";
import Geocode from "react-geocode";
import BranchCard from "../../elements/BranchCard";
// import MenuCard from "../../elements/MenuCard";
import DealCard from "../../elements/DealCard";
import Account from "../Account";
import { safeStringify } from 'ajv/dist/compile/codegen/code';

const Admin = (props) => {
  const history = useHistory();
  const currentUser = inMemoryTokens.getUser();
  const currentUserId = inMemoryTokens.getUserId();
  const headers = inMemoryTokens.getHeaders();
  const [selectedTabButton, setSelectedTabButton] = useState('INFO');
  const [restaurants, setRestaurants] = useState([]);

  // eslint-disable-next-line no-undef
  Geocode.setApiKey(cnst.googleApiKey);

  const getRestaurants = async () => {
    const url = `${cnst.apiBaseUrl()}restaurant/byaccount/${currentUserId}`;
    try {
      const result = await trackPromise(axios.get(url, headers));
      const resultData = result.data;

      if (resultData.length > 0) {
        setRestaurants(resultData);
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'Error getting restaurants.'
      });
    }
  }

  const restaurantAddedEvent = (restaurant) => {
    let r = [...restaurants];
    r.push(restaurant);
    setRestaurants(r);
  }

  const restaurantUpdatedEvent = (restaurant) => {
    let r = [...restaurants];
    const idx = restaurants.findIndex(x => x.id === restaurant.id);
    if (idx >= 0) {
      r.splice(idx, 1, restaurant);
      setRestaurants(r);
    }
  }

  const branchAddedEvent = (branch) => {
    const r = [...restaurants];
    const idx = r.findIndex(x => x.id === branch.restaurantId);
    if (idx >= 0) {
      r[idx].branches.push(branch);
      setRestaurants(r);
    }
  }

  const branchUpdatedEvent = (branch) => {
    const r = [...restaurants];
    const idx = r.findIndex(x => x.id === branch.restaurantId);
    if (idx >= 0) {
      const branches = r[idx].branches;
      const branchIdx = branches.findIndex(x => x.id === branch.id);
      if (branchIdx >= 0) {
        r[idx].branches.splice(branchIdx, 1, branch);
        setRestaurants(r);
      }
    }
  }

  const categoryAddedEvent = (category) => {
    const r = [...restaurants];
    const restaurantId = category.branch.restaurantId;
    const branchId = category.branch.id;

    if (restaurantId) {
      const restaurantIndex = r.findIndex(x => x.id === restaurantId);
      if (restaurantIndex >= 0) {
        const branchIndex = r[restaurantIndex].branches.findIndex(x => x.id === branchId);
        if (branchIndex >= 0) {
          r[restaurantIndex].branches[branchIndex].categories.push(category);
          setRestaurants(r);
        }
      }
    }
  }

  const categoryUpdatedEvent = (category) => {
    const r = [...restaurants];
    const restaurantId = category.branch.restaurantId;
    const branchId = category.branch.id;
    if (restaurantId) {
      const restaurantIndex = r.findIndex(x => x.id === restaurantId);
      if (restaurantIndex >= 0) {
        const branchIndex = r[restaurantIndex].branches.findIndex(x => x.id === branchId);
        if (branchIndex >= 0) {
          const categoryIndex = r[restaurantIndex].branches[branchIndex].categories.findIndex(x => x.id === category.id);
          if (categoryIndex >= 0) {
            r[restaurantIndex].branches[branchIndex].categories.splice(categoryIndex, 1, category);
          }
        }
      }
    }
  }

  const menuAddedEvent = (menu) => {
    const r = [...restaurants];
    const menuRestaurantId = menu.category.branch.restaurantId;
    const menuBranchId = menu.category.branch.id;
    const menuCategoryId = menu.category.id;
    if (menuRestaurantId) {
      const restaurantIndex = r.findIndex(x => x.id === menuRestaurantId);
      if (restaurantIndex >= 0) {
        const branchIndex = r[restaurantIndex].branches.findIndex(x => x.id === menuBranchId);

        if (branchIndex >= 0) {
          const categoryIndex = r[restaurantIndex].branches[branchIndex].categories.findIndex(x => x.id === menuCategoryId);

          if (categoryIndex >= 0) {
            r[restaurantIndex].branches[branchIndex].categories[categoryIndex].menus.push(menu);
            setRestaurants(r);
          }
        }
      }
    }
  }

  const menuUpdatedEvent = (menu) => {
    const r = [...restaurants];
    const menuRestaurantId = menu.category.branch.restaurantId;
    const menuBranchId = menu.category.branch.id;
    const menuCategoryId = menu.category.id;
    
    if (menuRestaurantId) {
      const restaurantIndex = r.findIndex(x => x.id === menuRestaurantId);
      
      if (restaurantIndex >= 0) {
        const branchIndex = r[restaurantIndex].branches.findIndex(x => x.id === menuBranchId);
        
        if (branchIndex >= 0) {
          const categoryIndex = r[restaurantIndex].branches[branchIndex].categories.findIndex(x => x.id === menuCategoryId);
          if (categoryIndex >= 0) {
            const menuIndex = r[restaurantIndex].branches[branchIndex].categories[categoryIndex].menus.findIndex(x => x.id === menu.id);
            if (menuIndex >= 0) {
              r[restaurantIndex].branches[branchIndex].categories[categoryIndex].menus.splice(menuIndex, 1, menu);
              setRestaurants(r);
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (currentUser === null) {
      history.push("/");
    }

    getRestaurants();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, currentUser]);

  const onSelectButtonTab = item => (e) => {
    setSelectedTabButton(item);
  };

  const renderComponent = () => {
    switch (selectedTabButton) {
      case 'INFO':
        return <RestaurantInfo 
                  restaurantsProp={restaurants} 
                  restaurantUpdatedEvent={restaurantUpdatedEvent} 
                  restaurantAddedEvent={restaurantAddedEvent} />
      case 'BRANCHES':
        return <Branches
                  restaurantsProp={restaurants}
                  branchAddedEvent={branchAddedEvent}
                  branchUpdatedEvent={branchUpdatedEvent} />
      // case 'MENU':
      //   return <Menu restaurantsProp={restaurants}
      //             categoryAddedEvent={categoryAddedEvent}
      //             categoryUpdatedEvent={categoryUpdatedEvent} 
      //             menuAddedEvent={menuAddedEvent}
      //             menuUpdatedEvent={menuUpdatedEvent} />
      case 'DEALS':
        return <Deals restaurantsProp={restaurants} />
      case 'ACCOUNT':
        return <Account restaurantsProp={restaurants}/>
      default: 
        return <div></div>
    }
  };

  return (
    <div>
    {
      (currentUser !== null)
      ?
      <div className="container">
        <h2 className="mx-2 my-2">Admin Page</h2>
        {/* <p className="mx-2 my-2">This is the area where the business owner will manage their restaurant information.</p> */}

        <div className="btn-group" role="group" aria-label="">
          <button type="button" 
            title="Select Business Info"
            className={`btn ${selectedTabButton === 'INFO' ? 'btn-primary' : 'btn-outline-primary' }`}
            onClick={onSelectButtonTab('INFO')}>My Restaurants
          </button>
          <button type="button" 
            title="Select Branches"
            className={`btn ${selectedTabButton === 'BRANCHES' ? 'btn-primary' : 'btn-outline-primary' }`}
            onClick={onSelectButtonTab('BRANCHES')}
            disabled={restaurants.length===0}>Branches
          </button>
          {/* <button type="button" 
            title="Select Branches"
            className={`btn ${selectedTabButton === 'MENU' ? 'btn-primary' : 'btn-outline-primary' }`}
            onClick={onSelectButtonTab('MENU')}>Menu
          </button> */}
          <button type="button"
            title="Select Deals"
            className={`btn ${selectedTabButton === 'DEALS' ? 'btn-primary' : 'btn-outline-primary' }`}
            onClick={onSelectButtonTab('DEALS')}
            disabled={restaurants.length===0}>Deals
          </button>
          <button type="button"
            title="Manage Account"
            className={`btn ${selectedTabButton === 'ACCOUNT' ? 'btn-primary' : 'btn-outline-primary' }`}
            onClick={onSelectButtonTab('ACCOUNT')}>Account
          </button>
        </div>

        {
          renderComponent()
        }
      </div>
      :
      null
    }
    </div>
  )
};

const RestaurantInfo = ({restaurantsProp, restaurantAddedEvent, restaurantUpdatedEvent}) =>{
  const { register, handleSubmit, formState: { errors}, setValue, clearErrors } = useForm();
  const currentUserId = inMemoryTokens.getUserId();
  const headers = inMemoryTokens.getHeaders();
  const uploadFileHeaders = inMemoryTokens.getUploadFileHeaders();
  const [cardTitle, setCardTitle] = useState('My Restaurants');
  const [restaurants, setRestaurants] = useState(restaurantsProp);
  const [formMode, setFormMode] = useState(false);
  const [restaurantForm, setRestaurantForm] = useState({});
  const restaurantFormFields = ['name', 'accountId', 'createdDate', 'logoFile', 'active', 'ownername', 'ownerphone', 'facebook', 'instagram', 'youtube', 'twitter'];
  const [logo, setLogo] = useState('');
  const [restaurantActive, setRestaurantActive] = useState(false);

  useEffect(() => {
    setRestaurants(restaurantsProp);
  }, [restaurantsProp]);

  const gridColumns = [
    {
      id: 'id',
      hidden: true
    }, {
      id: 'name',
      name: 'Restaurant Name',
      width: '25%'
    }, {
      id: 'active',
      name: html('<div style="text-align: center;">Active</div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
          if (cell) {
              return html(`<i class="bi bi-check2-square"></i>`)
          } else {
              return html(`<i class="bi bi-square"></i>`)
          }
      },
      width: '5%'
  }, {
      id: 'ownerName',
      name: 'Owner',
      width: '15%'
    }, {
      id: 'ownerPhone',
      name: 'Owner Phone',
      width: '10%'
    }, {
      id: 'logoFile',
      width: '100px',
      name: html('<div style="text-align: center;">Logo</div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
        if (cell) {
          const src = `${cnst.logoBaseUrl()}${cell}`;
          return html(`<img src='${src}' alt="Logo" class="img-thumbnail">`)
        } else {
          return html(`<b>NONE</b>`)
        }
      }
    }, {
      id: 'facebook',
      name: html('<div style="text-align: center;"></div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
          if (cell) {
            return html(`<a href='${cell}' target='blank'><i class="bi bi bi-facebook"></i></a>`)
          } else {
            return html(`<i class="bi bi-facebook"></i>`)
          }
      },
      width: '5%'
    }, {
      id: 'instagram',
      name: html('<div style="text-align: center;"></div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
          if (cell) {
            return html(`<a href='${cell}' target='blank'><i class="bi bi bi-instagram"></i></a>`)
          } else {
            return html(`<i class="bi bi-instagram"></i>`)
          }
      },
      width: '5%'
    }, {
      id: 'twitter',
      name: html('<div style="text-align: center;"></div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
          if (cell) {
            return html(`<a href='${cell}' target='blank'><i class="bi bi bi-twitter"></i></a>`)
          } else {
            return html(`<i class="bi bi-twitter"></i>`)
          }
      },
      width: '5%'
    }, {
      id: 'youtube',
      name: html('<div style="text-align: center;"></div>'),
      attributes: (cell) => {
        return {
          'style': 'text-align: center;'
        }
      },
      formatter: (cell) => {
          if (cell) {
            return html(`<a href='${cell}' target='blank'><i class="bi bi bi-youtube"></i></a>`)
          } else {
            return html(`<i class="bi bi-youtube"></i>`)
          }
      },
      width: '5%'
    }, {
      name: 'Action',
      width: '10%',
      sort: false,
      formatter: (cell, row) => {
          return h('button', {
              className: 'btn btn-primary py-2 px-4 mr-2',
              onClick: editRestaurant(row.cells)
          }, 'Edit');
      }
    }
  ];

  const gridStyle = {
    table: 'tableGrid',
    th: 'thGrid'
  }

  const gridPagination = {
    enabled: true,
    limit: 10
  };

  const addRestaurantClick = () => {
    setFormMode(true);
    setCardTitle('Add New Restaurant');
    setLogo('');
    setRestaurantForm({});
    restaurantFormFields.forEach(field => setValue(field, null));
    clearErrors();
  };

  const editRestaurant = row => (e) => {
    const id = row[0].data;
    const name = row[1].data;
    const idx = restaurantsProp.findIndex(x => x.id === id);
    if (idx >= 0) {
      const restaurant = restaurantsProp[idx];
      const d = new Date();
      const t = d.getTime();

      if (restaurant.logoFile) {
        setLogo(`${cnst.logoBaseUrl() }${restaurant.logoFile}?t=${t}`);
      } else {
        setLogo('');
      }

      setRestaurantActive(restaurant.active);
      
      setRestaurantForm(restaurant);
      restaurantFormFields.forEach(field => setValue(field, restaurant[field]));
      setCardTitle(`Edit Restaurant: ${name}`);
      setFormMode(true);
    }
  };

  const onSaveClick = async (data) => {
    const hasLogoFile = (data.inputLogo.length > 0);
    data.active = restaurantActive;
    data.createdDate = new Date();
    try {
      if (restaurantForm.id) {
        // UPDATE
        if (hasLogoFile) {
          const file = data.inputLogo[0];
          const fileName = file.name;
          const fileExt = fileName.split('.').pop();
          data.logoFile = `${restaurantForm.id}.${fileExt}`;
        }
        const url = `${cnst.apiBaseUrl()}restaurant/update/${restaurantForm.id}`;
        const result = await trackPromise(axios.put(url, data, headers));
        const resultData = result.data;

        if (resultData.operationSuccess) {

          if (hasLogoFile) {
            const file = data.inputLogo[0];
            const fileName = file.name;
            const fileExt = fileName.split('.').pop();
            const newFileName = `${restaurantForm.id}.${fileExt}`;
            let fileUpload = new FormData();
            fileUpload.append('file', file, newFileName);
            const uploadUrl = `${cnst.apiBaseUrl()}restaurant/uploadlogo`;
            const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));

            if (uploadResult) {
              Swal.fire({
                icon: 'success',
                title: `${cnst.applicationName}`,
                text: resultData.operationMessage
              }).then(() => {
                const restaurant = resultData.restaurant
                let r = [...restaurants];
                const idx = r.findIndex(x => x.id === restaurant.id);
                if (idx >= 0) {
                  r.splice(idx, 1, restaurant);
                  setFormMode(false);
                  // setRestaurants(r);
                  setRestaurantForm({});
                  restaurantUpdatedEvent(restaurant);
                }
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: `${cnst.applicationName}`,
                text: uploadResult
              });              
            }
            
          } else {
            Swal.fire({
              icon: 'success',
              title: `${cnst.applicationName}`,
              text: resultData.operationMessage
            }).then(() => {
              const restaurant = resultData.restaurant
              let r = [...restaurantsProp];
              const idx = r.findIndex(x => x.id === restaurant.id);
              if (idx >= 0) {
                r.splice(idx, 1, restaurant);
                setFormMode(false);
                // setRestaurants(r);
                setRestaurantForm({});
                restaurantUpdatedEvent(restaurant);
              }
            });
          }

        } else {
          Swal.fire({
            icon: 'error',
            title: `${cnst.applicationName}`,
            text: resultData.errorMessage
          });
        }
      } else {
        // ADD
        if (hasLogoFile) {
          const file = data.inputLogo[0];
          const fileName = file.name;
          const fileExt = fileName.split('.').pop();
          data.logoFile = fileExt;
        }

        data.accountId = currentUserId;
        const url = `${cnst.apiBaseUrl()}restaurant/add`;
        const result = await trackPromise(axios.post(url, data, headers));
        const resultData = result.data;

        if (resultData.operationSuccess) {
          if (hasLogoFile) {
            const file = data.inputLogo[0];
            const fileName = file.name;
            const fileExt = fileName.split('.').pop();
            const newFileName = `${resultData.restaurant.id}.${fileExt}`;
            let fileUpload = new FormData();
            fileUpload.append('file', file, newFileName);
            const uploadUrl = `${cnst.apiBaseUrl()}restaurant/uploadlogo`;
            const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));

            if (uploadResult) {
              Swal.fire({
                icon: 'success',
                title: `${cnst.applicationName}`,
                text: resultData.operationMessage
              }).then(() => {
                  setFormMode(false);
                  restaurantAddedEvent(resultData.restaurant);
                  // let r = [...restaurants];
                  // r.push(resultData.restaurant);
                  // setRestaurants(r);
                  setRestaurantForm({});
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: `${cnst.applicationName}`,
                text: uploadResult
              });              
            }

          } else {
            Swal.fire({
              icon: 'success',
              title: `${cnst.applicationName}`,
              text: resultData.operationMessage
            }).then(() => {
                setFormMode(false);
                restaurantAddedEvent(resultData.restaurant)
                // let r = [...restaurants];
                // r.push(resultData.restaurant);
                // setRestaurants(r);
                setRestaurantForm({});
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: `${cnst.applicationName}`,
            text: resultData.errorMessage
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: `${cnst.applicationName}`,
        text: 'An error has occurred saving the restaurant.'
      });
    }
  };

  const onCancelClick = async () => {
    setFormMode(false);
    setCardTitle(`My Restaurants`);
  };

  const onCheckboxActiveClick = () => {
    setRestaurantActive(!restaurantActive);
  }

  return (
    <div className="card rounded-lg mt-3 animate__animated animate__backInLeft">

      <div className="card-header">
        <div className="row mb-2">
          <div className="col-9">
            <h5 className="card-title">
              {cardTitle}
            </h5>
          </div>
      
          <div className="col-3 text-end">
            {
              (formMode)
              ?
              null
              :
              <button id="btnAddUser" className="btn btn-info" 
                onClick={addRestaurantClick} >
                Add New Restaurant
              </button>
            }
          </div>
        </div>
      </div>
      
      <div className="card-body">
        <>
        {
          (formMode) 
        
          ?

          <form className="p-2" onSubmit={handleSubmit(onSaveClick)} autoComplete="off">
            
            {/* Restaurant Name */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputName" className="col-md-2 col-form-label">Restaurant Name:</label>
                <div className="col-md-6">
                  <input id="inputName"
                    name="name"
                    type="text"
                    maxLength="50"
                    className="form-control"
                    defaultValue={restaurantForm.name}
                    {...register('name', { required: true })}
                  />
                </div>
              </div>
              
              <div className="row">
              <div className="col-md-6 offset-md-2 text-start">
                {
                  errors.name?.type === "required" && 
                  <span className="text-danger fs-6 fw-bold">Restaurant name is required</span>
                }
              </div>
            </div>
            </>

            {/* Active Flag */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputActive" className="col-md-2 col-form-label">{`Active`}</label>
                <div className="col-md-6">
                  <div className="form-check form-switch">
                    <input id="inputActive" 
                      className="form-check-input" 
                      type="checkbox" 
                      onClick={onCheckboxActiveClick}
                      defaultChecked={restaurantActive}/>
                  </div>
                </div>
              </div>
            </>

            {/* Owner Name */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputOwnerName">Owner Name:</label>
                <div className="col-md-6">
                    <input id="inputOwnerName"
                        name="ownername"
                        type="text"
                        maxLength="50"
                        className="form-control"
                        defaultValue={restaurantForm.ownerName}
                        {...register('ownername', { required: true })}
                    />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                {
                  errors.ownername?.type === "required" && 
                  <span className="text-danger fs-6 fw-bold">Owner name is required</span>
                }
                </div>
              </div>
            </>

            {/* Owner Phone */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputOwnerPhone">Owner Phone:</label>
                <div className="col-md-6">
                  <input id="inputOwnerPhone"
                    name="ownerphone"
                    type="text"
                    maxLength="20"
                    className="form-control"
                    defaultValue={restaurantForm.ownerPhone}
                    {...register('ownerphone')}
                  />
                </div>
              </div>
            </>

            {/* Facebook */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputFacebook">Facebook:</label>
                <div className="col-md-5">
                  <input id="inputFacebook"
                    name="facebook"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    defaultValue={restaurantForm.facebook}
                    {...register('facebook')}
                  />
                </div>
              </div>
            </>

            {/* Instagram */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputInstagram">Instagram:</label>
                <div className="col-md-5">
                  <input id="inputInstagram"
                    name="instagram"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    defaultValue={restaurantForm.instagram}
                    {...register('instagram')}
                  />
                </div>
              </div>
            </>

            {/* Twitter */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputTwitter">Twitter:</label>
                <div className="col-md-5">
                  <input id="inputTwitter"
                    name="twitter"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    defaultValue={restaurantForm.twitter}
                    {...register('twitter')}
                  />
                </div>
              </div>
            </>

            {/* Youtube */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputYoutube">Youtube:</label>
                <div className="col-md-6">
                  <input id="inputYoutube"
                    name="instagram"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    defaultValue={restaurantForm.youtube}
                    {...register('youtube')}
                  />
                </div>
              </div>
            </>

            {/* Logo */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputLogo">Logo:</label>
                <div className="custom-file col-md-6">
                  <input 
                    id="customFile" 
                    name="inputLogo" 
                    type="file" 
                    className="custom-file-input" 
                    
                    {...register('inputLogo')}
                  />
                </div>
              </div>

              {
                (logo.length > 0)
                ?
                <>
                <div className="row mt-2">
                  <img className="offset-md-2 img-fluid logo-size" src={logo} alt="Dingo"/>
                </div> 
                </>
                :
                null
              }
            </>
            
            {/* Save and Cancel buttons */}
            <>
              <div className="row mt-4">
                {/* <label className="col-sm-2 col-form-label"></label> */}
                <div className="col-md-6 offset-md-2">
                  <button id="btnSave" className="btn btn-primary mr-2" type="submit" >
                    Save
                  </button>
                      
                  <button id="btnCancelView" className="btn btn-warning mx-2" onClick={onCancelClick}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          </form>

          :
          <Grid
            data={restaurantsProp}
            columns={gridColumns}
            pagination={gridPagination}
            search={true}
            resizable={true}
            sort={false}
            className={gridStyle}>
          </Grid>
        }
        </>
      </div>
    </div>
  )
};

const Branches = ({restaurantsProp, branchAddedEvent, branchUpdatedEvent}) => {
  const { register, handleSubmit, formState: { errors}, setValue, clearErrors } = useForm();
  // const currentUserId = inMemoryTokens.getUserId();
  const headers = inMemoryTokens.getHeaders();
  const [cardTitle, setCardTitle] = useState('Branches');
  const [restaurants, setRestaurants] = useState(restaurantsProp);
  const [selectRestaurants, setSelectRestaurants] = useState([]);
  const [formMode, setFormMode] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [branches, setBranches] = useState([]);
  const [branchForm, setBranchForm] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [geoCodeRequired, setGeoCodeRequired] = useState(false);
  const branchFormFields = ['address', 'altPhone', 'city', 'contactPerson', 'createdDate', 'email', 'id', 'name', 'phone', 'postalCode', 'provinceCode', 'restaurantId', 'website', 'latitude', 'longitude'];

  useEffect(() => {
    let rp = [...restaurantsProp];
    if (rp.length > 1) {
      let allBranches = [];
      rp.forEach(element => {
        allBranches = allBranches.concat(element.branches);
      });

      rp.splice(0, 0, { id: '0', name: 'Show All', branches: allBranches});
      setSelectedRestaurant('0');
      setBranches(allBranches);
    } else {
      setSelectedRestaurant(rp[0].id);
      if (rp[0].branches) {
        setBranches(rp[0].branches);
      }
    }

    setSelectRestaurants(rp);
    setRestaurants(restaurantsProp);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvinces = async () => {
    const url = `${cnst.apiBaseUrl()}utility/provinces`;
    try {
      const result = await trackPromise(axios.get(url, headers));
      const resultData = result.data;
      setProvinces(resultData);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'Error getting provinces.'
      });
    }
  };

  useEffect(() => {
    getProvinces();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const addBranchClick = () => {
    setFormMode(true);
    setCardTitle(`Add Branch`);
    setGeoCodeRequired(true);
    branchFormFields.forEach(field => setValue(field, null));
    clearErrors();
  };

  const onSaveBranchClick = async (data, e) => {
    try {
      if (geoCodeRequired) {
        const fullAddress = `${data.address} ${data.city} ${data.provinceCode} ${data.postalCode}`;
        const geoCodeResponse = await Geocode.fromAddress(fullAddress);
        const { lat, lng } = geoCodeResponse.results[0].geometry.location;
        data.latitude = lat;
        data.longitude = lng;
        data.createdDate = new Date();
      }

      if (branchForm.id) {
        // UPDATE
        const url = `${cnst.apiBaseUrl()}branch/update/${branchForm.id}`;
        const result = await trackPromise(axios.put(url, data, headers));
        const resultData = result.data;
        const branch = resultData.branch;
        if (resultData.operationSuccess) {
          Swal.fire({
            icon: 'success',
            title: `${cnst.applicationName}`,
            text: resultData.operationMessage
          }).then(() => {
              setFormMode(false);
              let br = [...branches];
              const idx = br.findIndex(x => x.id === branchForm.id);
              if (idx >= 0) {
                br.splice(idx, 1, branch);
                setFormMode(false);
                setBranches(br);
                setBranchForm({});
                e.target.reset();
                branchUpdatedEvent(branch);
              }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: `${cnst.applicationName}`,
            text: resultData.errorMessage
          });
        }
      } else {
        // ADD
        const url = `${cnst.apiBaseUrl()}branch/add`;
        const result = await trackPromise(axios.post(url, data, headers));
        const resultData = result.data;
        const branch = resultData.branch;
        if (resultData.operationSuccess) {
          Swal.fire({
            icon: 'success',
            title: `${cnst.applicationName}`,
            text: resultData.operationMessage
          }).then(() => {
              setFormMode(false);
              let br = [...branches];
              br.push(branch);
              setBranches(br);
              e.target.reset();

              branchAddedEvent(branch);
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: `${cnst.applicationName}`,
            text: resultData.errorMessage
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: `${cnst.applicationName}`,
        text: 'An error has occurred saving the branch.'
      });
    }
  };

  const onCardEditButtonClick = async (item) => {
    setGeoCodeRequired(!item.latitude | !item.longitude)
    setBranchForm(item);
    branchFormFields.forEach(field => setValue(field, item[field]));
    setFormMode(true);
  };

  const onSelectButtonTab = (id) => {
    setSelectedRestaurant(id);
  };

  const onCancelClick = async () => {
    setFormMode(false);
    setBranchForm({});
    setCardTitle(`Branch List`);
  };

  return (
    <div className="card rounded-lg mt-3 animate__animated animate__backInLeft">
      
      <div className="card-header">
        <div className="row mb-2">
          <div className="col-9">
            <h5 className="card-title">
              {cardTitle}
            </h5>
          </div>

          <div className="col-3 text-end">
            {
              (formMode)
              ?
              null
              :
              <button id="btnAddUser" className="btn btn-info" 
                onClick={addBranchClick} >
                Add New Branch
              </button>
            }
            </div>
        </div>
      </div>

      <div className="card-body">
        <>
        {
          (formMode)
          ?
          <form className="p-2" onSubmit={handleSubmit(onSaveBranchClick)} autoComplete="off">
            <div className="row mt-2">
              <label htmlFor="inputSelectRestaurant" className="col-md-2 col-form-label">Restaurant</label>
              <div className="col-md-4">
                <select id="inputSelectRestaurant" 
                  className="form-control minimal"
                  name="restaurantId"
                  {...register('restaurantId')}
                >
                  {restaurants.map((res) =>
                    <option key={res.id}
                      value={res.id}>{res.name}
                    </option>)}
                </select>
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputName" className="col-md-2 col-form-label">Branch Name:</label>
              <div className="col-md-4">
                <input id="inputName"
                  name="name"
                  type="text"
                  maxLength="50"
                  className="form-control"
                  {...register('name', { required: true })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 offset-md-2 text-start">
              {
                errors.name?.type === "required" && 
                <span className="text-danger fs-6 fw-bold">Branch name is required</span>
              }
              </div>
            </div>
            
            <div className="row mt-2">
              <label htmlFor="inputAddress" className="col-md-2 col-form-label">Address:</label>
              <div className="col-md-6">
                <input id="inputAddress"
                  name="address"
                  type="text"
                  maxLength="300"
                  className="form-control"
                  {...register('address', { required: true })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 offset-md-2 text-start">
              {
                errors.address?.type === "required" && 
                <span className="text-danger fs-6 fw-bold">Address is required</span>
              }
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputCity" className="col-md-2 col-form-label">City:</label>
              <div className="col-md-3">
                <input id="inputCity"
                  name="city"
                  type="text"
                  maxLength="50"
                  className="form-control"
                  {...register('city', { required: true })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 offset-md-2 text-start">
              {
                errors.city?.type === "required" && 
                <span className="text-danger fs-6 fw-bold">City is required</span>
              }
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputProvince" className="col-md-2 col-form-label">Province:</label>
              <div className="col-md-3">
                <select id="inputSelectProvince" 
                  className="form-control minimal"
                  name="provinceCode"
                  {...register('provinceCode')}
                >
                  {provinces.map((res) =>
                    <option key={res.code}
                      value={res.code}>{res.name}
                    </option>)}
                </select>
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputPostalCode" className="col-md-2 col-form-label">Postal Code:</label>
              <div className="col-md-2">
                <input id="inputPostalCode"
                  name="postalCode"
                  type="text"
                  maxLength="10"
                  className="form-control"
                  // defaultValue={branchForm.postalCode}
                  {...register('postalCode', { required: true })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 offset-md-2 text-start">
              {
                errors.postalCode?.type === "required" && 
                <span className="text-danger fs-6 fw-bold">Postal Code is required</span>
              }
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputPhone" className="col-md-2 col-form-label">Phone:</label>
              <div className="col-md-3">
                <input id="inputPhone"
                  name="phone"
                  type="text"
                  maxLength="20"
                  className="form-control"
                  {...register('phone')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputWebsite" className="col-md-2 col-form-label">Website:</label>
              <div className="col-md-6">
                <input id="inputWebsite"
                  name="website"
                  type="text"
                  maxLength="100"
                  className="form-control"
                  {...register('website')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputEmail" className="col-md-2 col-form-label">Email:</label>
              <div className="col-md-6">
                <input id="inputEmail"
                  name="email"
                  type="text"
                  maxLength="50"
                  className="form-control"
                  {...register('email')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputAltPhone" className="col-md-2 col-form-label">Alternate Phone:</label>
              <div className="col-md-3">
                <input id="inputAltPhone"
                  name="altPhone"
                  type="text"
                  maxLength="50"
                  className="form-control"
                  {...register('altPhone')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputContactPerson" className="col-md-2 col-form-label">Contact Person:</label>
              <div className="col-md-4">
                <input id="inputContactPerson"
                  name="contactPerson"
                  type="text"
                  maxLength="50"
                  className="form-control"
                  {...register('contactPerson')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputLatitude" className="col-md-2 col-form-label">Latitude:</label>
              <div className="col-md-4">
                <input id="inputLatitude"
                  name="latitude"
                  type="text"
                  readOnly
                  className="form-control"
                  {...register('latitude')}
                />
              </div>
            </div>

            <div className="row mt-2">
              <label htmlFor="inputLongitude" className="col-md-2 col-form-label">Longitude:</label>
              <div className="col-md-4">
                <input id="inputLongitude"
                  name="longitude"
                  type="text"
                  readOnly
                  className="form-control"
                  {...register('longitude')}
                />
              </div>
            </div>

            <div className="row mt-4">
              {/* <label className="col-sm-2 col-form-label"></label> */}
              <div className="col-md-6 offset-md-2">
                <button id="btnSave" className="btn btn-primary mr-2" type="submit" >
                  Save
                </button>
                    
                <button id="btnCancelView" className="btn btn-warning mx-2" onClick={onCancelClick}>
                  Cancel
                </button>
              </div>
            </div>


          </form>
          :
          <>
            <div className="row mb-2">
              <span className="h6">Restaurants:</span>
              <div className="btn-group">
              
              {
                selectRestaurants.map (res => (
                    <button key={res.id}
                      type="button" 
                      className={`btn ${selectedRestaurant === res.id ? 'btn-primary' : 'btn-outline-primary' }`}
                      onClick={() => onSelectButtonTab(res.id)}>
                      {res.name}
                    </button>
                ))
              }
              </div>
            </div>
            <div className="row mb-2">
              {
                branches
                  .filter(br => {
                    if (selectedRestaurant !== '0') {
                      return br.restaurantId === selectedRestaurant
                    }
                    return true;
                  })
                  .map (br => (
                  <div className="col-lg-6 col-md-6 col-sm-12 my-2 animate__animated animate__slideInUp" 
                    key={br.id}>
                    <BranchCard 
                      name={br.name}
                      restaurant={(br.restaurant) ? (br.restaurant.name) : ''}
                      address={br.address}
                      cityProvincePostalCode={`${br.city}, ${br.province.name} ${br.postalCode}`}
                      contactPerson={br.contactPerson}
                      phone={br.phone}
                      website={br.website}
                      email={br.email}
                      cardEditButtonClick={() => onCardEditButtonClick(br)} />
                  </div>
                ))
              }
            </div>
          </>

        }
        </>
      </div>

      <div className="card-footer">
      </div>

    </div>
  )
};

const Deals = ({restaurantsProp }) => {
  const headers = inMemoryTokens.getHeaders();
  const uploadFileHeaders = inMemoryTokens.getUploadFileHeaders();
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm();
  const [restaurants, setRestaurants] = useState(restaurantsProp);
  const [cardTitle, setCardTitle] = useState('My Deals');
  const [formMode, setFormMode] = useState(false);
  const [deals, setDeals] = useState([]);
  const [allDeals, setAllDeals] = useState([]);
  const [dealForm, setDealForm] = useState({});
  const [branches, setBranches] = useState([]);
  const [formDefaultBranches, setFormDefaultBranches] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('0');
  const [dealActive, setDealActive] = useState(true);
  const [dealImageFile, setDealImageFile] = useState('');
  const dealFormFields = ['title', 'subtitle', 'shortNote', 
    'description', 'price', 'originalPrice', 'startDate', 'endDate'
  ];
  const [formDisplayImage, setFormDisplayImage] = useState('');
  const [formSelectedRestaurantId, setFormSelectedRestaurantId] = useState('');
  const [formSelectedBranchId, setFormSelectedBranchId] = useState('');
  const [mon, setMon] = useState(true);
  const [tue, setTue] = useState(true);
  const [wed, setWed] = useState(true);
  const [thu, setThu] = useState(true);
  const [fri, setFri] = useState(true);
  const [sat, setSat] = useState(true);
  const [sun, setSun] = useState(true);

  useEffect(() => {
    let rp = [...restaurantsProp];

    if (rp.length > 0) {
      // If there is more than one restaurant, append All.
      rp.splice(0, 0, {id: '0', name: 'Show All', branches: []});
      
      // Get the index of the restaurant where a branch exists.
      let i = -1;
      rp.some((elem, index) => {
        i = index;
        return elem.branches.length > 0;
      })

      if (i >= 0) {
        setSelectedRestaurant(rp[i].id);
        setFormSelectedRestaurantId(rp[i].id);

        // Set the default branches retrieved.
        const defBranches = [...rp[i].branches];
        setFormDefaultBranches(defBranches);

        let br = [...rp[i].branches];
        if (br.length > 0) {
          setFormSelectedBranchId(br[0].id);
        }
        
        // If there is more than one branch, append All.
        br.splice(0, 0, { id: '0', name: 'Show All'});
        setBranches(br);
        if (br.length > 0) {
          setSelectedBranch(br[0].id);
        }
      } else {
        // Only one restaurant.
        setSelectedRestaurant(rp[0].id);
        setFormSelectedRestaurantId(rp[0].id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Triggered when restaurant is selected.
  useEffect(() => {
    if (selectedRestaurant) {
      let rp = [...restaurantsProp];
      const idx = rp.findIndex(x=>x.id===selectedRestaurant);
      if (idx >= 0) {
        let br = [...rp[idx].branches];

        if (br.length > 0) {
          // If there is more than one branch, append All.
          br.splice(0, 0, { id: '0', name: 'Show All'});
          setBranches(br);
          setSelectedBranch(br[0].id);
        } else {
          Swal.fire({
            icon: 'info',
            title: `${cnst.applicationName}`,
            text: `No branch defined for this restaurant.`
          });
        }

        getDeals();
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurant])

  // Triggered when branch is selected.
  useEffect(() => {
    if (selectedBranch) {
      if (selectedBranch === '0') {
        setDeals(allDeals);
      } else {
        const dealsFiltered = [...allDeals].filter(x => x.branchId === selectedBranch);
        setDeals(dealsFiltered);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranch])

  const getDeals = async () => {
    const url = `${cnst.apiBaseUrl()}deal/byrestaurant/${selectedRestaurant}`;
    try {
      const result = await trackPromise(axios.get(url, headers));
      const resultData = result.data;
      if (resultData.length > 0) {
        setAllDeals(resultData);
        setDeals(resultData);
      } else {
        setAllDeals([]);
        setDeals([]);
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'Error getting deals.'
      });
    }
  }

  const addDeal = () => {
    setDealForm({});
    clearErrors();
    setFormMode(true);
    setCardTitle('Add New Deal');
    dealFormFields.forEach(field => setValue(field, null));
    setDealImageFile('');
  }

  const editDeal = async(item) => {
    item.startDate = item.startDate.substring(0, 10);
    item.endDate = item.endDate.substring(0, 10);
    setMon(item.monday);
    setTue(item.tuesday);
    setWed(item.wednesday);
    setThu(item.thursday);
    setFri(item.friday);
    setSat(item.saturday);
    setSun(item.sunday);
    setDealForm(item);
    clearErrors();
    setFormMode(true);
    setCardTitle('Edit Deal');
    dealFormFields.forEach(field => setValue(field, item[field]));
    setDealActive(item.active);
    setDealImageFile((item.imageFile) ? item.imageFile: '');
    if (item.imageFile) {
      setFormDisplayImage(`${cnst.dealImageBaseUrl()}${item.imageFile}?t=${Date.now()}`)
    } else {
      setFormDisplayImage(`${cnst.dealImageBaseUrl()}placeholder.jpg`)
    }
    
    setFormSelectedRestaurantId(item.restaurantId);
    setFormSelectedBranchId(item.branchId);
  }

  const afterDealAdded = (resultData, imageUploaded) => {
    Swal.fire({
      icon: 'success',
      title: `${cnst.applicationName}`,
      text: resultData.operationMessage
    }).then(() => {
      clearErrors();
      setFormMode(false);
      dealFormFields.forEach(field => setValue(field, null));
      setCardTitle('My Deals');
      const newDeal = resultData.deal;
      const ad = [...allDeals];
      
      if (imageUploaded) {
        newDeal.imageFile = `${newDeal.id}.${newDeal.imageFile}`
      }
      ad.push(newDeal);
      setAllDeals(ad);

      if (selectedBranch === '0') {
        setDeals(ad);
      } else {
        if (selectedBranch === newDeal.branchId) {
          const dd = [...deals];
          dd.push(newDeal);
          setDeals(dd);
        }
      }

      reset();
    })
  }

  const afterDealUpdated = (resultData, imageUploaded) => {
    Swal.fire({
      icon: 'success',
      title: `${cnst.applicationName}`,
      text: resultData.operationMessage
    }).then(() => {
      clearErrors();
      setFormMode(false);
      dealFormFields.forEach(field => setValue(field, null));
      setCardTitle('My Deals');
      setDealImageFile('');

      const updatedDeal = resultData.deal;
      if (imageUploaded) {
        updatedDeal.imageFile = `${updatedDeal.id}.${updatedDeal.imageFile}`
      }
      const ad = [...allDeals];
      const idx = ad.findIndex(x => x.id === updatedDeal.id);
      if (idx >= 0) {
        ad.splice(idx, 1, updatedDeal);
        setAllDeals(ad);
      }

      if (selectedBranch === '0') {
        setDeals(ad);
      } else {
        if (selectedBranch === updatedDeal.branchId) {
          const dd = [...ad];
          const idxDeals = dd.findIndex(x => x.id === updatedDeal.id);
          if (idxDeals >= 0) {
            dd.splice(idx, 1, updatedDeal);
            setDeals(dd);
          }
        } else {
          const dd = [...ad].filter(x => x.branchId === selectedBranch);
          setDeals(dd);
        }
      }

      reset();
    })
  }

  const onSaveClick = async (data) => {
    const hasImageFile = (data.inputImageFile.length > 0);
    let file = null;
    let fileName = '';
    let fileExt = '';

    try {
      data.restaurantId = formSelectedRestaurantId;

      if (formSelectedBranchId) {
        data.branchId = formSelectedBranchId;

        if (hasImageFile) {
          file = data.inputImageFile[0];
          fileName = file.name;
          fileExt = fileName.split('.').pop();
          data.imageFile = fileExt;
        }

        data.monday = mon;
        data.tuesday = tue;
        data.wednesday = wed;
        data.thursday = thu;
        data.friday = fri;
        data.saturday = sat;
        data.sunday = sun;

        if (dealForm.id) {
          // UPDATE
          data.active = dealActive;

          if (hasImageFile) {
            if (!dealForm.imageFile) {
              data.imageFile = `${fileExt}`;
            }
          } else {
            data.imageFile = dealForm.imageFile;
          }
          
          const url = `${cnst.apiBaseUrl()}deal/update/${dealForm.id}`;
          const result = await trackPromise(axios.put(url, data, headers));
          const resultData = result.data;
          
          if (resultData.operationSuccess) {
            if (hasImageFile) {
              let fileUpload = new FormData();
              const newFileName = `${dealForm.id}.${fileExt}`;
              fileUpload.append('file', file, newFileName);
              const uploadUrl = `${cnst.apiBaseUrl()}deal/uploaddealimage/${dealForm.id}`;
              const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));
              if (uploadResult) {
                data.inputImageFile = '';
                afterDealUpdated(resultData, true);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `${cnst.applicationName}`,
                  text: `${cnst.errUploadDealImage}`
                });
              }
            } else {
              afterDealUpdated(resultData, false);
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: `${cnst.applicationName}`,
              text: `${resultData.operationMessage}`
            }); 
          }
        } else {
          // ADD
          data.active = dealActive;
          const url = `${cnst.apiBaseUrl()}deal/add`;
          const result = await trackPromise(axios.post(url, data, headers));
          const resultData = result.data;

          if (resultData.operationSuccess) {
            if (hasImageFile) {
              // Save the image.
              let fileUpload = new FormData();
              const newFileName = `${resultData.deal.id}.${fileExt}`;
              fileUpload.append('file', file, newFileName);
              const uploadUrl = `${cnst.apiBaseUrl()}deal/uploaddealimage/${resultData.deal.id}`;
              const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));
              if (uploadResult.data) {
                afterDealAdded(resultData, true);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `${cnst.applicationName}`,
                  text: `${cnst.errUploadDealImage}`
                });
              }
            } else {
              afterDealAdded(resultData, false);
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: `${cnst.applicationName}`,
              text: `${resultData.operationMessage}`
            }); 
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `${cnst.applicationName}`,
          text: `No branch selected for the deal.`
        }).then(() => {
          return;
        });
      }      
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: `${cnst.applicationName}`,
        text: 'An error has occurred saving deal.'
      });
    }
  }

  const onCancelClick = async () => {
    setFormMode(false);
    setCardTitle(`My Deals`);
  }

  const onDealActiveClick = () => {
    setDealActive(!dealActive);
  }

  const onRestaurantChange = (e) => {
    let rp = [...restaurantsProp];
    const idx = rp.findIndex(x => x.id===e);
    if (idx >= 0) {
      let br = [...rp[idx].branches];

      // Check if there is a branch.
      if (br.length === 0) {
        setFormDefaultBranches([]);
        setFormSelectedBranchId(null);
        Swal.fire({
          icon: 'info',
          title: `${cnst.applicationName}`,
          text: `No branch defined for this restaurant.`
        });
      } else {
        setFormSelectedBranchId(br[0].id);
        setFormDefaultBranches(br);
      }
    }
    setFormSelectedRestaurantId(e);
  }

  const getDaysAvailable = (item) => {
    const days = [];
    if (item.monday) days.push('Mon');
    if (item.tuesday) days.push('Tue');
    if (item.wednesday) days.push('Wed');
    if (item.thursday) days.push('Thu');
    if (item.friday) days.push('Fri');
    if (item.saturday) days.push('Sat');
    if (item.sunday) days.push('Sun');
    return(days.length > 0) ? days.join(', ') : 'N/A';
  }
  
  return (
    <div className="card rounded-lg mt-3 animate__animated animate__backInLeft">
      <div className="card-header">
        <div className="row mb-2">
          <div className="col-9">
            <h5 className="card-title">
              {cardTitle}
            </h5>
          </div>

          <div className="col-3 text-end">
            {
              (formMode)
              ?
              null
              :
              <button id="btnAddUser" className="btn btn-info" 
                onClick={addDeal} >
                Add New Deal
              </button>
            }
          </div>
        </div>
      </div>

      <div className="card-body">
        <>
        {
          (formMode)
          ?
          <form className="p-2" onSubmit={handleSubmit(onSaveClick)} autoComplete="off">
            
            {/* Deal Title */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputTitle" className="col-md-2 col-form-label">Title:</label>
                <div className="col-md-6">
                  <input id="inputTitle"
                    name="title"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    defaultValue={dealForm.title}
                    {...register('title', { required: true })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                  {
                    errors.name?.type === "required" && 
                    <span className="text-danger fs-6 fw-bold">Deal title is required</span>
                  }
                </div>
              </div>
            </>

            {/* Deal Subtitle */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputSubtitle" className="col-md-2 col-form-label">Subtitle:</label>
                  <div className="col-md-6">
                    <input id="inputSubtitle"
                      name="subtitle"
                      type="text"
                      maxLength="100"
                      className="form-control"
                      defaultValue={dealForm.subtitle}
                      {...register('subtitle')}
                    />
                </div>
              </div>
            </>
            
            {/* Deal Short Note */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputShortNote" className="col-md-2 col-form-label">Short Note</label>
                <div className="col-md-6">
                  <textarea id="inputShortNote"
                    name="shortNote"
                    type="text"
                    maxLength="100"
                    rows="2"
                    className="form-control"
                    {...register('shortNote')}
                  />
                </div>
              </div>
            </>

            {/* Deal Description */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputDescription" className="col-md-2 col-form-label">Description</label>
                <div className="col-md-6">
                  <textarea id="inputDescription"
                    name="description"
                    type="text"
                    maxLength="300"
                    rows="3"
                    className="form-control"
                    {...register('description')}
                  />
                </div>
              </div>
            </>

            {/* Original Price */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputOriginalPrice" className="col-md-2 col-form-label">Original Price</label>
                <div className="col-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">$</div>
                    </div>
                    <input id="inputOriginalPrice"
                      name="originalPrice"
                      type="number"
                      className="form-control"
                      step=".01"
                      {...register('originalPrice', { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                  {
                    errors.originalPrice?.type === "required" && 
                    <span className="text-danger fs-6 fw-bold">Original price is required</span>
                  }
                </div>
              </div>
            </>

            {/* Deal Price */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputPrice" className="col-md-2 col-form-label">Deal Price</label>
                <div className="col-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">$</div>
                    </div>
                    <input id="inputPrice"
                      name="price"
                      type="number"
                      className="form-control"
                      step=".01"
                      {...register('price', { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                  {
                    errors.price?.type === "required" && 
                    <span className="text-danger fs-6 fw-bold">Price is required</span>
                  }
                </div>
              </div>
            </>

            {/* Start Date */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputStartDate" className="col-md-2 col-form-label">Start Date:</label>
                <div className="col-md-2">
                  <input id="inputStartDate"
                    name="startDate"
                    type="date"
                    maxLength="50"
                    className="form-control"
                    defaultValue={dealForm.startDate}
                    {...register('startDate', { required: true })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                  {
                    errors.name?.type === "required" && 
                    <span className="text-danger fs-6 fw-bold">Start date is required</span>
                  }
                </div>
              </div>
            </>

            {/* End Date */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputEndDate" className="col-md-2 col-form-label">End Date:</label>
                <div className="col-md-2">
                  <input id="inputEndDate"
                    name="endDate"
                    type="date"
                    maxLength="50"
                    className="form-control"
                    defaultValue={dealForm.endDate}
                    {...register('endDate', { required: true })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 offset-md-2 text-start">
                  {
                    errors.name?.type === "required" && 
                    <span className="text-danger fs-6 fw-bold">Start date is required</span>
                  }
                </div>
              </div>
            </>
            
            {/* Restaurant Dropdown */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputSelectRestaurant" className="col-md-2 col-form-label">Restaurant Select</label>
                <div className="col-md-4">
                  <select id="inputSelectRestaurant" 
                    className="form-control minimal"
                    name="restaurantId"
                    value={formSelectedRestaurantId}
                    onChange={(e) => {onRestaurantChange(e.currentTarget.value)}}
                    // {...register('restaurantId')}
                  >
                    {restaurants.map((res) =>
                      <option key={res.id}
                        value={res.id}>{res.name}
                      </option>)}
                  </select>
                </div>
              </div>
            </>

            {/* Branch Selection */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputSelectBranch" className="col-md-2 col-form-label">Branch</label>
                <div className="col-md-4">
                  <select id="inputSelectBranch" 
                    className="form-control minimal"
                    name="branchId"
                    value={formSelectedBranchId}
                    onChange={(e) => {setFormSelectedBranchId(e.currentTarget.value)}}
                  >
                    {formDefaultBranches.map((res) =>
                      <option key={res.id}
                        value={res.id}>{res.name}
                      </option>)}
                  </select>
                </div>
              </div>
            </>

            {/* Available Days */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputSelectBranch" className="col-md-2 col-form-label">Days Available</label>
                <div className="col-md-8">
                  <div class="row">
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputMonday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setMon(!mon)}
                            defaultChecked={mon}/>
                            Mon
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2">
                          <input id="inputTuesday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setTue(!tue)}
                            defaultChecked={tue} />
                            Tue
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputMonday" 
                            className="form-check-input" 
                            type="checkbox"
                            onClick={() => setWed(!wed)}
                            defaultChecked={wed} />
                            Wed
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputThursday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setThu(!thu)}
                            defaultChecked={thu} />
                            Thu
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputMonday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setFri(!fri)}
                            defaultChecked={fri} />
                            Fri
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputMonday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setSat(!sat)}
                            defaultChecked={sat} />
                            Sat
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div className="form-check form-switch">
                        <label class="mr-2" for="inputMonday">
                          <input id="inputMonday" 
                            className="form-check-input" 
                            type="checkbox" 
                            onClick={() => setSun(!sun)}
                            defaultChecked={sun} />
                            Sun
                        </label>
                      </div>
                    </div>
                  </div>                 
                </div>
              </div>
            </>

            {/* Active Flag */}
            <>
              <div className="row mt-2">
                <label htmlFor="inputActive" className="col-md-2 col-form-label">{`Active`}</label>
                <div className="col-md-6">
                  <div className="form-check form-switch">
                    <input id="inputActive" 
                      className="form-check-input" 
                      type="checkbox" 
                      onClick={onDealActiveClick}
                      defaultChecked={dealActive}/>
                  </div>
                </div>
              </div>
            </>

            {/* Deal Photo */}
            <>
              <div className="row mt-2">
                <label className="col-md-2 col-form-label" htmlFor="inputImageFile">Deal Photo:</label>
                <div className="custom-file col-md-6">
                  <input 
                    id="customFile" 
                    name="inputImageFile" 
                    type="file" 
                    className="custom-file-input" 
                    {...register('inputImageFile')}
                  />
                </div>
              </div>

              {
                (dealImageFile.length > 0)
                ?
                <>
                <div className="row mt-2">
                  <img className="offset-md-2 img-fluid logo-size" src={formDisplayImage} alt="Dingo"/>
                </div> 
                </>
                :
                null
              }
            </>

            
            

            {/* Save and Cancel Buttons */}
            <div className="row mt-4">
              <div className="col-md-6 offset-md-2">
                <button id="btnSave" className="btn btn-primary mr-2" type="submit" >
                  Save
                </button>
                    
                <button id="btnCancel" className="btn btn-warning mx-2" onClick={onCancelClick}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
          :
          <>
            <div className="row my-2">
              {/* Restaurant Selection */}
              <div className="col-md-4">
                <label htmlFor="inputSelectRestaurant" 
                  className="form-label">Restaurant
                </label>
                <select id="inputSelectRestaurant" 
                  className="form-control minimal"
                  name="restaurantId"
                  value={selectedRestaurant}
                  onChange={e => setSelectedRestaurant(e.currentTarget.value)}>
                  {
                    restaurants.map((res) =>
                    <option key={res.id}
                      value={res.id}>{res.name}
                    </option>)
                  }
                </select>
              </div>

              {/* Branch Selection */}
              <div className="col-md-4">
                <label htmlFor="inputSelectBranch" 
                  className="form-label">Branch
                </label>
                <select id="inputSelectBranch" 
                  className="form-control minimal"
                  name="branchId"
                  value={selectedBranch}
                  onChange={e => setSelectedBranch(e.currentTarget.value)}>
                  {
                    branches.map((res) =>
                    <option key={res.id}
                      value={res.id}>{res.name}
                    </option>)}
                </select>
              </div>
            </div>

            <div className="row">
              {
                deals
                .map (de => (
                  <div className="col-lg-4 col-md-6 col-sm-12 my-2" key={de.id}>
                    <DealCard
                      title={de.title}
                      subtitle={de.subtitle}
                      shortNote={de.shortNote}
                      description={de.description}
                      price={de.price}
                      originalPrice={de.originalPrice}
                      startDate={de.startDate}
                      endDate={de.endDate}
                      restaurantName={de.restaurant.name}
                      branchName={de.branch.name}
                      daysAvailable={getDaysAvailable(de)}
                      active={de.active}
                      imageFile={de.imageFile}
                      cardEditButtonClick={()=> editDeal(de)}>
                    </DealCard>
                  </div>
                  
                ))
              }
            </div>
          </>
        }
        </>
          
      </div>
    </div>
  )
};


export default Admin;



// const Menu = ({
//   restaurantsProp, 
//   categoryAddedEvent, 
//   categoryUpdatedEvent, 
//   menuAddedEvent, 
//   menuUpdatedEvent}) => {
//   const { register, handleSubmit, formState: { errors}, setValue } = useForm();
//   const headers = inMemoryTokens.getHeaders();
//   const uploadFileHeaders = inMemoryTokens.getUploadFileHeaders();
//   const [cardTitle] = useState('Menu Items');
//   const [restaurants] = useState(restaurantsProp);
//   const [branches, setBranches] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedRestaurant, setSelectedRestaurant] = useState('');
//   const [selectedBranch, setSelectedBranch] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [itemToDisplay, setItemToDisplay] = useState('MENU_LIST');
//   const categoryFormFields = ['name', 'description', 'active', 'branchId'];
//   const [categoryPanelTitle, setCategoryPanelTitle] = useState('');
//   const [menuPanelTitle, setMenuPanelTitle] = useState('Menu');
//   const [menus, setMenus] = useState([]);
//   const menuFormFields = ['name', 'shortDescription', 'longDescription', 'imageFile', 'categoryId', 'price'];
//   const [menuPhoto, setMenuPhoto] = useState('');
//   const [selectedMenu, setSelectedMenu] = useState({});

//   useEffect(() => {
//     let rp = [...restaurantsProp];

//     if (rp.length > 0) {
//       setSelectedRestaurant(rp[0].id);
//       setBranches(rp[0].branches);
//       if (rp[0].branches.length > 0) {
//         setSelectedBranch(rp[0].branches[0].id);
//         setCategories(rp[0].branches[0].categories);
//       }
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   // Triggered when restaurant is selected.
//   useEffect(() => {
//     const rp = [...restaurantsProp];
//     const idx = rp.findIndex(x => x.id === selectedRestaurant);
    
//     if (idx >= 0) {
//       const branchesList = rp[idx].branches;
//       setBranches(branchesList);

//       if (branchesList.length > 0) {
//         setSelectedBranch(branchesList[0].id);
//         setCategories(branchesList[0].categories);
//       }
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedRestaurant]);

//   // Triggered when a branch is selected.
//   useEffect(() => {
//     const rp = [...restaurantsProp];
//     const idx = rp.findIndex(x => x.id === selectedRestaurant);

//     if (idx >= 0) {
//       const branchesList = rp[idx].branches;
//       const branchIdx = branchesList.findIndex(x => x.id === selectedBranch);
//       if (branchIdx >= 0) {
//         const categoriesList = branchesList[branchIdx].categories;
//         setCategories(categoriesList);
//         if (categoriesList.length > 0) {
//           setSelectedCategory(categoriesList[0]);
//           setMenuPanelTitle(`${categoriesList[0].name} Menu List`);
//           setMenus(categoriesList[0].menus);
//         } else {
//           setMenuPanelTitle(`Menu List`);
//           setMenus([]);
//         }
//       }

//       setItemToDisplay('MENU_LIST');
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedBranch]);

//   // Triggered when category is selected. 
//   useEffect(() => {
//     const mpTitle = selectedCategory.id ? `${selectedCategory.name} Menu List` : `Menu List`
//     setMenuPanelTitle(`${mpTitle}`);
//     if (itemToDisplay === 'MENU_LIST') {
//       // Get Menu Based on Category Selected.
//       if (selectedCategory.id) {
//         getMenuByCategory(selectedCategory.id);
//       }
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedCategory]);

//   const getMenuByCategory = async (id) => {
//     const url = `${cnst.apiBaseUrl()}menu/bycategory/${id}`;
//     try {
//       const result = await trackPromise(axios.get(url, headers));
//       const resultData = result.data;
//       if (resultData.length > 0) {
//         setMenus(resultData);
//       } else {
//         setMenus([]);
//       }
//     } catch (e) {
//       Swal.fire({
//         icon: 'error',
//         title: 'DingoDeals',
//         text: 'Error getting menu.'
//       });
//     }
//   }

//   const addCategory = () => {
//     setMenuPanelTitle(``);
//     setCategoryPanelTitle(`Add Category`);
//     setItemToDisplay('CATEGORY_FORM');
//     categoryFormFields.forEach(field => setValue(field, null));
//     setSelectedCategory({});
//   }

//   const editCategory = async (item) => {
//     setSelectedCategory(item);
//     setCategoryPanelTitle(`Edit Category: ${item.name}`);
//     setItemToDisplay('CATEGORY_FORM');
//     categoryFormFields.forEach(field => setValue(field, item[field]));
//   }

//   const onCancelCategoryEdit = () => {
//     if (!selectedCategory.id) {
//       // Exiting form while adding category.
//       setMenuPanelTitle(`Menu Listing`);
//     }

//     setItemToDisplay('MENU_LIST');
//   }

//   const saveCategory = async (data) => {
//     try {
//       if (selectedCategory.id) {
//         // UPDATE
//         const url = `${cnst.apiBaseUrl()}category/update/${selectedCategory.id}`;
//         const result = await trackPromise(axios.put(url, data, headers));
//         const resultData = result.data;
//         const category = resultData.category;

//         if (resultData.operationSuccess) {
//           Swal.fire({
//             icon: 'success',
//             title: `${cnst.applicationName}`,
//             text: resultData.operationMessage
//           }).then(() => {
//             setItemToDisplay('MENU_LIST');
//             let ca = [...categories];
//             const idx = ca.findIndex(x => x.id === selectedCategory.id);
//             if (idx >= 0) {
//               ca.splice(idx, 1, category);
//               setCategories(ca);
//               setSelectedCategory(category);
//             }
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: `${cnst.applicationName}`,
//             text: resultData.errorMessage
//           });
//         }
//       } else {
//         // ADD
//         data.branchId = selectedBranch;
//         const url = `${cnst.apiBaseUrl()}category/add`;
//         const result = await trackPromise(axios.post(url, data, headers));
//         const resultData = result.data;
//         const category = resultData.category;
//         if (resultData.operationSuccess) {
//           Swal.fire({
//             icon: 'success',
//             title: `${cnst.applicationName}`,
//             text: resultData.operationMessage
//           }).then(() => {
//               setItemToDisplay('MENU_LIST');
//               let ca = [...categories];
//               ca.push(category);
//               setCategories(ca);
//               setSelectedCategory(category);
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: `${cnst.applicationName}`,
//             text: resultData.errorMessage
//           });
//         }
//       }
//     } catch (e) {
//       Swal.fire({
//         icon: 'error',
//         title: `${cnst.applicationName}`,
//         text: 'An error has occurred saving the category.'
//       });
//     }
//   }

//   const addMenu = () => {
//     setMenuPhoto('');
//     setMenuPanelTitle(`Add Menu`);
//     setItemToDisplay('MENU_FORM');
//     menuFormFields.forEach(field => setValue(field, null));
//   }

//   const editMenu = async (item) => {
//     setSelectedMenu(item);
//     setMenuPanelTitle(`Edit ${item.name}`);
//     setItemToDisplay('MENU_FORM');
//     menuFormFields.forEach(field => setValue(field, item[field]));
//     setMenuPhoto(`${cnst.menuImageBaseUrl()}${item.imageFile}`);
//   }

//   const onCancelMenuForm = () => {
//     setMenuPanelTitle(`Menu Listing`);
//     setItemToDisplay('MENU_LIST');
//   }

//   const saveMenu = async (data) => {
//     data.categoryId = selectedCategory.id;
//     const hasMenuImage = (data.inputMenuPhoto.length > 0);

//     try {
//       if (selectedMenu.id) {
//         // UPDATE
//         let file = null;
//         let fileName = '';
//         let fileExt = '';
        
//         if (hasMenuImage) {
//           file = data.inputMenuPhoto[0];
//           fileName = file.name;
//           fileExt = fileName.split('.').pop();
//           data.imageFile = fileExt;
//         }
        
//         const url = `${cnst.apiBaseUrl()}menu/update/${selectedMenu.id}`;
//         const result = await trackPromise(axios.put(url, data, headers));
//         const resultData = result.data;

//         if (resultData.operationSuccess) {
//           const mpTitle = selectedCategory ? `${selectedCategory.name} Menu List` : `Menu List`
//           setMenuPanelTitle(`${mpTitle}`);
//           let mnu = resultData.menu;

//           if (hasMenuImage) {
//             const newFileName = `${resultData.menu.id}.${fileExt}`;
//             mnu.imageFile = newFileName;
//             let fileUpload = new FormData();
//             fileUpload.append('file', file, newFileName);
//             const uploadUrl = `${cnst.apiBaseUrl()}menu/uploadmenuimage/${resultData.menu.id}`;
//             const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));

//             if (uploadResult) {
//               Swal.fire({
//                 icon: 'success',
//                 title: `${cnst.applicationName}`,
//                 text: resultData.operationMessage
//               }).then(() => {
//                 setItemToDisplay('MENU_LIST');
//                 menuUpdatedEvent(mnu);
//                 let m = [...menus];
                
//                 const idx = m.findIndex(x => x.id === resultData.menu.id);
//                 if (idx >= 0) {
//                   mnu.imageFile = newFileName;
//                   m.splice(idx, 1, mnu);
//                   setMenus(m);
//                 }
//                 setSelectedMenu({});
//               });
//             } else {

//             }
//           } else {
//             Swal.fire({
//               icon: 'success',
//               title: `${cnst.applicationName}`,
//               text: resultData.operationMessage
//             }).then(() => {
//               setItemToDisplay('MENU_LIST');
//               menuUpdatedEvent(resultData.menu);
//               let m = [...menus];
//               const idx = m.findIndex(x => x.id === resultData.menu.id);
//               if (idx >= 0) {
//                 m.splice(idx, 1, resultData.menu);
//                 setMenus(m);
//               }
//               setSelectedMenu({});
//             });
//           }
//         }
        
//       } else {
//         // ADD
//         let file = null;
//         let fileName = '';
//         let fileExt = '';
        
//         if (hasMenuImage) {
//           file = data.inputMenuPhoto[0];
//           fileName = file.name;
//           fileExt = fileName.split('.').pop();
//           data.imageFile = fileExt;
//         }

//         const url = `${cnst.apiBaseUrl()}menu/add`;
//         const result = await trackPromise(axios.post(url, data, headers));
//         const resultData = result.data;
        
//         if (resultData.operationSuccess) {
//           const mpTitle = selectedCategory ? `${selectedCategory.name} Menu List` : `Menu List`
//           setMenuPanelTitle(`${mpTitle}`);
          
//           if (hasMenuImage) {
//             const newFileName = `${resultData.menu.id}.${fileExt}`;
//             let fileUpload = new FormData();
//             fileUpload.append('file', file, newFileName);
//             const uploadUrl = `${cnst.apiBaseUrl()}menu/uploadmenuimage/${resultData.menu.id}`;
//             const uploadResult = await trackPromise(axios.post(uploadUrl, fileUpload, uploadFileHeaders));

//             if (uploadResult.data) {
//               Swal.fire({
//                 icon: 'success',
//                 title: `${cnst.applicationName}`,
//                 text: resultData.operationMessage
//               }).then(() => {
//                 setItemToDisplay('MENU_LIST');
//                 menuAddedEvent(resultData.menu);
//                 let m = [...menus];
//                 let mnu = resultData.menu;
//                 mnu.imageFile = newFileName;
//                 m.push(mnu);
//                 setMenus(m);
//                 setSelectedMenu({});
//               })
//             }
//           } else {
//             Swal.fire({
//               icon: 'success',
//               title: `${cnst.applicationName}`,
//               text: resultData.operationMessage
//             }).then(() => {
//               setItemToDisplay('MENU_LIST');
//               menuAddedEvent(resultData.menu);
//               let m = [...menus];
//               m.push(resultData.menu);
//               setMenus(m);
//               setSelectedMenu({});
//             });
//           }
//         }
//       }
//     } catch (e) {
//       Swal.fire({
//         icon: 'error',
//         title: `${cnst.applicationName}`,
//         text: 'An error has occurred saving the menu.'
//       });
//     }
//   }

//   return (
//       <div className="card rounded-lg mt-3 animate__animated animate__backInLeft">
//         <div className="card-header">
//           <div className="row mb-2">
//             <div className="col-9">
//               <h5 className="card-title">
//                 {cardTitle}
//               </h5>
//             </div>
//           </div>
//         </div>
      
//         <div className="card-body">
//           <div className="row my-2">
//             {/* Restaurant Selection */}
//             <div className="col-md-4">
//               <label htmlFor="inputSelectRestaurant" 
//                 className="form-label">Restaurant
//               </label>
//               <select id="inputSelectRestaurant" 
//                 className="form-control minimal"
//                 name="restaurantId"
//                 value={selectedRestaurant}
//                 onChange={e => setSelectedRestaurant(e.currentTarget.value)}>
//                 {
//                   restaurants.map((res) =>
//                   <option key={res.id}
//                     value={res.id}>{res.name}
//                   </option>)
//                 }
//               </select>
//             </div>
            
//             {/* Branch Selection */}
//             <div className="col-md-4">
//               <label htmlFor="inputSelectBranch" 
//                 className="form-label">Branch
//               </label>
//               <select id="inputSelectBranch" 
//                 className="form-control minimal"
//                 name="branchId"
//                 onChange={e => setSelectedBranch(e.currentTarget.value)}>
//                 {
//                   branches.map((res) =>
//                   <option key={res.id}
//                     value={res.id}>{res.name}
//                   </option>)}
//               </select>
//             </div>
//           </div>

//           <div>
//             <div className="row">
//               {/* Category List */}
//               <div className="col-sm-3">
//                 <ul className="list-group">
//                   {categories.map((cat, index) => 
//                     <li key={index} 
//                       className={`list-group-item ${selectedCategory.id===cat.id ? 'active': ''}`}
//                       onClick={() => setSelectedCategory(cat)}>
//                       <div className="d-flex flex-row">
//                         <span className="d-flex flex-fill">
//                           {cat.name}
//                         </span>
//                         <span className="d-flex align-items-end">
//                           <button onClick={() => editCategory(cat)}>
//                             <i className="far fa-edit"></i>
//                           </button>
//                         </span>
//                       </div>
//                     </li>
//                   )}
//                 </ul>
                
//                 <div className="d-grid gap-2">
//                   <button className="btn btn-primary mt-2" 
//                     type="button" onClick={addCategory}>
//                       Add New Category
//                   </button>
//                 </div>
                
//               </div>
              
//               {/* Menu List and Form Placeholder */}
//               <div className="col-sm-9">
//                 {
//                   (() => {
//                     switch(itemToDisplay) {
//                       case 'CATEGORY_FORM':
//                         return <div className="border m-2 p-2">
//                           <h2>{categoryPanelTitle}</h2>
//                           <form className="p-2" onSubmit={handleSubmit(saveCategory)} autoComplete="off">
//                             <div className="row mt-2">
//                               <label htmlFor="inputName" className="col-md-2 col-form-label">Name</label>
//                               <div className="col-md-4">
//                                 <input id="inputName"
//                                   name="name"
//                                   type="text"
//                                   maxLength="50"
//                                   className="form-control"
//                                   {...register('name', { required: true })}
//                                 />
//                               </div>
//                             </div>

//                             <div className="row">
//                               <div className="col-md-6 offset-md-2 text-start">
//                                 {
//                                   errors.name?.type === "required" && 
//                                   <span className="text-danger fs-6 fw-bold">Category name is required</span>
//                                 }
//                               </div>
//                             </div>
                          
//                             <div className="row mt-2">
//                               <label htmlFor="inputDescription" className="col-md-2 col-form-label">Description</label>
//                               <div className="col-md-6">
//                                 <textarea id="inputDescription"
//                                   name="name"
//                                   type="text"
//                                   maxLength="500"
//                                   rows="2"
//                                   className="form-control"
//                                   {...register('description')}
//                                 />
//                               </div>
//                             </div>

//                             <div className="row mt-2">
//                               <label htmlFor="inputActive" className="col-md-2 col-form-label">Active:</label>
//                               <div className="col-md-2">
//                                 <input id="inputActive"
//                                   name="active"
//                                   type="checkbox"
//                                   {...register('active')}
//                                   className="big-checkbox mt-1 mb-2"
//                                 />
//                               </div>
//                             </div>

//                             <div className="row mt-4">
//                               <div className="col-md-6 offset-md-2">
//                                 <button id="btnSave" className="btn btn-primary mr-2" type="submit" >
//                                   Save
//                                 </button>
                                    
//                                 <button id="btnCancelView" className="btn btn-warning mx-2" onClick={onCancelCategoryEdit}>
//                                   Cancel
//                                 </button>
//                               </div>
//                             </div>
//                           </form>
//                         </div>
//                       case 'MENU_LIST':
//                         return <div className="border m-2 p-2">
//                           <div className="d-flex flex-row">
//                             <h2 className="d-flex flex-fill">
//                               {menuPanelTitle}
//                             </h2>
//                             <span className="d-flex align-items-end">
//                               <button className="btn btn-outline-primary" 
//                                 onClick={() => addMenu()}>
//                                 Add
//                               </button>
//                             </span>
//                           </div>
                          
//                           <div className="row">
//                             {
//                               menus
//                                 .map (me => (
//                                 <div className="col-lg-4 col-md-6 col-sm-12 my-2" key={me.id}>
//                                   <MenuCard
//                                     name={me.name}
//                                     priceDisplay={cnst.moneyFormat(me.price)}
//                                     shortDescription={me.shortDescription}
//                                     longDescription={me.longDescription}
//                                     category={selectedCategory}
//                                     imageFile={me.imageFile}
//                                     editButtonClick={() => editMenu(me)}
//                                   >
//                                   </MenuCard>
//                                 </div>
//                               ))
//                             }
//                           </div>
//                         </div>
//                       case 'MENU_FORM':
//                         return <div className="border m-2 p-2">
//                           <h2>{menuPanelTitle}</h2>
//                           <form className="p-2" onSubmit={handleSubmit(saveMenu)} autoComplete="off">
//                             <div className="row mt-2">
//                               <label htmlFor="inputName" className="col-md-2 col-form-label">Name</label>
//                               <div className="col-md-4">
//                                 <input id="inputName"
//                                   name="name"
//                                   type="text"
//                                   maxLength="30"
//                                   className="form-control"
//                                   {...register('name', { required: true })}
//                                 />
//                               </div>
//                             </div>
//                             <div className="row">
//                               <div className="col-md-6 offset-md-2 text-start">
//                                 {
//                                   errors.name?.type === "required" && 
//                                   <span className="text-danger fs-6 fw-bold">Menu name is required</span>
//                                 }
//                               </div>
//                             </div>
                              
//                             <div className="row mt-2">
//                               <label htmlFor="inputShortDescription" className="col-md-2 col-form-label">Short Description</label>
//                               <div className="col-md-6">
//                                 <textarea id="inputShortDescription"
//                                   name="shortDescription"
//                                   type="text"
//                                   maxLength="50"
//                                   rows="2"
//                                   className="form-control"
//                                   {...register('shortDescription', { required: true })}
//                                 />
//                               </div>
//                             </div>

//                             <div className="row">
//                               <div className="col-md-6 offset-md-2 text-start">
//                                 {
//                                   errors.shortDescription?.type === "required" && 
//                                   <span className="text-danger fs-6 fw-bold">Short description is required</span>
//                                 }
//                               </div>
//                             </div>

//                             <div className="row mt-2">
//                               <label htmlFor="inputLongDescription" className="col-md-2 col-form-label">Long Description</label>
//                               <div className="col-md-6">
//                                 <textarea id="inputLongDescription"
//                                   name="longDescription"
//                                   type="text"
//                                   maxLength="500"
//                                   rows="2"
//                                   className="form-control"
//                                   {...register('longDescription')}
//                                 />
//                               </div>
//                             </div>

//                             <div className="row mt-2">
//                               <label htmlFor="inputPrice" className="col-md-2 col-form-label">Price</label>
//                               <div className="col-md-3">
//                                 <div className="input-group">
//                                   <div className="input-group-prepend">
//                                     <div className="input-group-text">$</div>
//                                   </div>
//                                   <input id="inputPrice"
//                                     name="price"
//                                     type="number"
//                                     maxLength="30"
//                                     className="form-control"
//                                     step=".01"
//                                     {...register('price', { required: true })}
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row">
//                               <div className="col-md-6 offset-md-2 text-start">
//                                 {
//                                   errors.price?.type === "required" && 
//                                   <span className="text-danger fs-6 fw-bold">Price is required</span>
//                                 }
//                               </div>
//                             </div>

//                             <div className="row mt-2">
//                               <label className="col-md-2 col-form-label" htmlFor="inputLogo">Menu Image:</label>
//                               <div className="custom-file col-md-6">
//                                 <input 
//                                   id="customFile" 
//                                   name="inputMenuPhoto" 
//                                   type="file" 
//                                   className="custom-file-input" 
                                  
//                                   {...register('inputMenuPhoto')}
//                                 />
//                               </div>
//                             </div>

//                             {
//                               (menuPhoto.length > 0)
//                               ?
//                               <>
//                               <div className="row mt-2">
//                                 <img className="offset-md-2 img-fluid logo-size" 
//                                   src={menuPhoto} alt="Dingo"/>
//                               </div> 
//                               </>
//                               :
//                               null
//                             }
                            
//                             <div className="row mt-4">
//                               <div className="col-md-6 offset-md-2">
//                                 <button id="btnSave" className="btn btn-primary mr-2" type="submit" >
//                                   Save
//                                 </button>
                                    
//                                 <button id="btnCancelView" className="btn btn-warning mx-2" onClick={onCancelMenuForm}>
//                                   Cancel
//                                 </button>
//                               </div>
//                             </div>
                            
//                           </form>
//                         </div>;
//                       default:
//                         return <span>Menu List</span>;
//                     }
//                   })()
//                 }
//               </div>
//             </div>
//           </div>            
        
//         </div>
      
//         <div className="card-footer">
//           { itemToDisplay }
//         </div>
//       </div>
//   )
// }
