// import React, { useEffect, useState } from 'react';
import React from 'react';

const BranchCard = (
  {
    name,
    restaurant,
    address, 
    cityProvincePostalCode,
    contactPerson,
    phone,
    website,
    email,
    cardEditButtonClick
  }) => {

  return (
    <div className="card rounded-lg">

      <div className="card-body">
        <h4 className="card-title">{name}</h4>
        <h6 className="card-subtitle text-muted mb-2">{restaurant}</h6>
        <div className="row mx-2">
          <div className="col-lg-3 col-md-12">
            <span className="text-muted text-end">Address</span>
          </div>
          <div className="col-lg-9 col-md-12">
            {address}
          </div>
        </div>

        <div className="row mx-2">
          <div className="col-lg-3 col-md-12">
            <span className="text-muted text-end"></span>
          </div>
          <div className="col-lg-9 col-md-12">
            {cityProvincePostalCode}
          </div>
        </div>

        <div className="row mx-2">
          <div className="col-lg-3">
            <span className="text-muted text-end">Contact</span>
          </div>
          <div className="col-lg-9">
            {contactPerson}
          </div>
        </div>

        <div className="row mx-2">
          <div className="col-lg-3">
            <span className="text-muted text-end">Phone</span>
          </div>
          <div className="col-lg-9">
            {phone}
          </div>
        </div>

        <div className="row mx-2">
          <div className="col-lg-3">
            <span className="text-muted text-end">Website</span>
          </div>
          <div className="col-lg-9">
            {website}
          </div>
        </div>

        <div className="row mx-2">
          <div className="col-lg-3">
            <span className="text-muted text-end">Email</span>
          </div>
          <div className="col-lg-9">
            {email}
          </div>
        </div>

      </div>
      <div className="card-footer">
        <div className="d-grid gap-2">
          <button className="btn btn-info mx-2" 
            onClick={cardEditButtonClick}>
            Edit
          </button>
        </div>
      </div>
    </div>
  )
};

export default BranchCard;