import React, { useEffect, useState } from "react";
import inMemoryTokens from '../../helpers/inMemoryTokens';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
// import { nil } from "ajv";


const Account = (props) => {
  const currentUserId = inMemoryTokens.getUserId();
  const headers = inMemoryTokens.getHeaders();
  const [stripePromise] = useState(() => loadStripe('pk_test_51IX6pvEB4pdKg5UMDzcLNhHrZK5CP4rPv1VEMcrWeLNuchUE5kJzJUzESJYgCSpNJAmNJkmdRj5c1Es8kMm38u0i00ZIr5yTry'));
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState({
    subscriptionType: {
      name: '',
      statusCode: 'VLD',
      details: ''
    }
  });
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState({});
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [subscription, setSubscription] = useState({
    accountId: currentUserId,
    subscriptionTypeId: null,
    startDate: new Date(),
    endDate: new Date(),
    active: true,
    stripePaymentId: null
  });
  const [entityCount, setEntityCount] = useState({totalRestaurants: 0, totalBranches: 0});

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#009FE6",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#009FE6"
        },
        "::placeholder": {
          color: "#009FE6"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  }

  const CardField = ({ onChange }) => (
    <div className="form-control">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  )

  const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
      className={`btn btn-primary mt-2 ${error ? "btn-danger" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  )
  
  const ErrorMessage = ({ children }) => (
    <div className="alert alert-danger">
      {children}
    </div>
  )

  // const ResetButton = ({ onClick }) => (
  //   <button type="button" className="ResetButton" onClick={onClick}>
  //     <svg width="32px" height="32px" viewBox="0 0 32 32">
  //       <path
  //         fill="#FFF"
  //         d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
  //       />
  //     </svg>
  //   </button>
  // )
  
  const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    // const [paymentMethod, setPaymentMethod] = useState(null);
    const [billingDetails, setBillingDetails] = useState({
      email: "",
      phone: "",
      name: ""
    });

    const subscriptionObject = JSON.parse(JSON.stringify(props.subscription));

    const handleBillingChange = (evt) => {
      const value = evt.target.value;
      setBillingDetails({
        ...billingDetails,
        [evt.target.name]: value
      });

      setError(null);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        // Obtain payment intent
        const paymentIntentRequest = {
          item: "Subscription",
          subscriptionTypeId: props.subscription.subscriptionTypeId
        };
        const urlPayment = `${cnst.apiBaseUrl()}payment/stripepaymentintent`;
        const paymentResult = await trackPromise(axios.post(urlPayment, paymentIntentRequest, headers));
        const paymentIntentId = paymentResult.data;

        // Check if subscription type was selected.
        if (!selectedSubscriptionType.id) {
          Swal.fire({
            icon: 'warning',
            title: 'DingoDeals',
            text: 'Please select your subscription type.'
          });

          return;
        }

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        if (error) {
          elements.getElement("card").focus();
          return;
        }
    
        if (cardComplete) {
          setProcessing(true);
        }

        const payload = await stripe.confirmCardPayment(paymentIntentId, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: billingDetails
          }
        });
    
        setProcessing(false);
    
        if (payload.error) {
          setError(payload.error);
          console.log('payload.error', payload.error)
          Swal.fire({
            icon: 'warning',
            title: 'DingoDeals',
            text: 'An error has occured performing the payment. Please try again.'
          });

          return;
          
        } else {
          subscriptionObject.stripePaymentId = payload.paymentIntent.id;

          const url = `${cnst.apiBaseUrl()}subscription/add`;
          const result = await trackPromise(axios.post(url, subscriptionObject, headers));
          const resultData = result.data

          if (resultData.operationSuccess) {
            Swal.fire({
              icon: 'success',
              title: `${cnst.applicationName}`,
              text: resultData.operationMessage
            }).then(() => {
              props.handlePaymentSuccess(resultData.subscription);
              reset();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: `${cnst.applicationName}`,
              text: resultData.errorMessage
            });
          }
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: `${cnst.applicationName}`,
          text: 'An error has occurred saving the subscription.'
        });
      }
    }
  
    const reset = () => {
      setError(null);
      setProcessing(false);
      // setPaymentMethod(null);
      setBillingDetails({
        email: "",
        phone: "",
        name: ""
      });
    }
  
    return (
      <form className="Form" onSubmit={handleSubmit}>
        <div className="row mt-2">
          <label htmlFor="inputName" className="col-md-2 col-form-label">Name:</label>
          <div className="col-md-6">
          <input id="inputName"
            name="name"
            type="text"
            className="form-control"
            required
            value={billingDetails.name}
            onChange={handleBillingChange}
            // onChange={(e) => {
            //   setBillingDetails({ ...billingDetails, name: e.target.value });
            // }}
          />
          </div>
        </div>

        <div className="row mt-2">
          <label htmlFor="inputEmail" className="col-md-2 col-form-label">Email:</label>
          <div className="col-md-6">
          <input id="inputEmail"
            name="email"
            type="text"
            className="form-control"
            required
            value={billingDetails.email}
            onChange={handleBillingChange}
            // onChange={(e) => {
            //   setBillingDetails({ ...billingDetails, email: e.target.value });
            // }}
          />
          </div>
        </div>

        <div className="row mt-2">
          <label htmlFor="inputPhone" className="col-md-2 col-form-label">Phone:</label>
          <div className="col-md-6">
            <input id="inputPhone"
              name="phone"
              type="text"
              className="form-control"
              required
              value={billingDetails.phone}
              onChange={handleBillingChange}
              // onChange={(e) => {
              //   setBillingDetails({ ...billingDetails, phone: e.target.value });
              // }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <label htmlFor="inputCard" className="col-md-2 col-form-label">Card Detail</label>
          <div className="col-md-6">
            <CardField
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6 offset-md-2">
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6 offset-md-2">
            <SubmitButton processing={processing} error={error} disabled={!stripe}>
              Pay with Card
            </SubmitButton>
          </div>
        </div>
      </form>
    );
  }
  
  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  }

  const getSubscriptionsByAccount = async () => {
    const url = `${cnst.apiBaseUrl()}subscription/byaccount/${currentUserId}`;
    try {
      const result = await trackPromise(axios.get(url, headers));
      const resultData = result.data;
      if (resultData.length > 0) {
        setSubscriptions(resultData);
        setCurrentSubscription(resultData[0]);
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'Error getting subscriptions.'
      });
    }
  }

  const getSubscriptionTypes = async () => {
    const url = `${cnst.apiBaseUrl()}subscription/subscriptiontype/active`;
    const countUrl = `${cnst.apiBaseUrl()}restaurant/count/byaccount/${currentUserId}`;
    try {
      const result = await trackPromise(axios.get(url, headers));
      const resultData = result.data;

      const countResult = await trackPromise(axios.get(countUrl, headers));
      const countResultData = countResult.data;

      if (resultData.length > 0) {
        const filteredSubscriptionTypes = resultData.filter(s => {
          return (s.maxRestaurants >= countResultData.restaurantsCount && s.maxBranches >= countResultData.branchesCount);
        });
        setSubscriptionTypes(filteredSubscriptionTypes);
      } else {
        Swal.fire({
          icon: 'info',
          title: 'DingoDeals',
          text: 'No subscriptions are currently setup. Please contact support.'
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'Error getting subscription types.'
      });
    }
  }

  useEffect(() => {
    getSubscriptionTypes();
    getSubscriptionsByAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectSubscriptionType = (st) => {
    setSelectedSubscriptionType(st);
    setSubscription({...subscription, subscriptionTypeId: st.id});
  }

  const handleSubscriptionChange = (obj) => {
    setSubscription(obj);
  }

  const handlePaymentSuccess = (subscriptionObject) => {
    // Reset subscription object
    setSubscription({
      accountId: currentUserId,
      subscriptionTypeId: null,
      startDate: new Date(),
      endDate: new Date(),
      active: true,
      stripePaymentId: null
    });

    // Clear selected subscription type
    setSelectedSubscriptionType({});

    setCurrentSubscription(subscriptionObject);
    setSubscriptions(subscriptions => [subscriptionObject, ...subscriptions]);
  }

  return (
    <div className="card rounded-lg mt-3">
      <div className="card-header">
        <div className="row">
          <div className="col-9">
            <h5 className="card-title">
              Subscription
            </h5>
          </div>
          <div className="col-3 text-end">
            <button id="btnAddUser" className="btn btn-info" 
              onClick={()=> setShowSubscriptions(!showSubscriptions)} >
              {(showSubscriptions) ? `Return` : `Show Subscription History`} 
            </button>
            
          </div>
        </div>
      </div>

      {
        (showSubscriptions)
        ?
        <div className="animate__animated animate__backInLeft">
          <h2 className="m-3">History</h2>
          <div className="row m-3">
            <ul className="list-group">
            {
              subscriptions.map((su, index) => 
                <li key={index} className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{su.subscriptionType.name}</div>
                    <span>
                      {`Subscription Validity: ${cnst.dateFormat(su.startDate)} - ${cnst.dateFormat(su.endDate)}`}
                    </span>
                    <br />
                    <span>
                      {`Payment Date: ${cnst.dateFormat(su.createdDate)}`}
                    </span>

                    
                  </div>
                  <span className={`badge rounded-pill ${(su.statusCode === 'VLD') ? 'bg-success' : 'bg-danger'}`}>
                    {`${su.statusDescription}`}
                  </span>
                </li>
              )
            }
            </ul>
          </div>
        </div>
        :
        <div className="animate__animated animate__backInLeft">
          {/* Current Subscription */}
          {
            (subscriptions.length > 0)
            ?
            <div className="row m-3">
              <div className={`alert ${(currentSubscription.statusCode === 'VLD') ? 'alert-success' : 'alert-danger'}`} role="alert">
                <h4 className="alert-heading">{`Current Plan: ${currentSubscription.subscriptionType.name}`}</h4>
                <div className="row">
                  <label htmlFor="staticStartDate" className="col-sm-2 col-form-label">Start Date</label>
                  <div className="col-sm-10">
                    <p className="form-control-plaintext">{cnst.dateFormat(currentSubscription.startDate)}</p>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="staticEndDate" className="col-sm-2 col-form-label">End Date Date</label>
                  <div className="col-sm-10">
                    <p className="form-control-plaintext">{cnst.dateFormat(currentSubscription.endDate)}</p>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="staticStatus" className="col-sm-2 col-form-label">Status</label>
                  <div className="col-sm-10">
                    <p className="form-control-plaintext">{currentSubscription.statusDescription}</p>
                  </div>
                </div>
                <hr />
                <small class="text-muted">
                  {`You are currently on ${currentSubscription.subscriptionType.details}`}
                </small>
              </div>
            </div>
            :
            <>
            </>
          }

          {/* Subscription Type */}
          <div className="row m-2">
            <h2>Choose your Subscription Plan</h2>
            {
              subscriptionTypes.map((st, index) => 
                <div className="col-lg-3 col-md-12 mb-4" key={index}>
                  <div className={`card h-100 ${(selectedSubscriptionType.id === st.id) ? 'card1Selected' : 'card1'}`}>
                  {/* <div className="card h-100 card1"> */}
                    <div className="card-body">
                      <h5 className="card-title">{st.name}</h5>
                      <small className='text-muted'>{st.details}</small>
                      <br /><br />
                      <span className="h2">{cnst.moneyFormat(st.amount)}</span>
                      <br /><br />
                      <div className="d-grid my-3">
                        <button onClick={() => selectSubscriptionType(st)}
                          className={`btn btn-block ${(selectedSubscriptionType.id === st.id) ? 'btn-dark' : 'btn-outline-dark'}`}>
                          {`${(selectedSubscriptionType.id === st.id) ? 'Selected' : 'Select'}`}
                        </button>
                      </div>
                      <ul>
                      {
                          (st.shortDescription1)
                          ?
                          <li>{st.shortDescription1}</li>
                          :
                          <></>
                        }
                        {
                          (st.shortDescription2)
                          ?
                          <li>{st.shortDescription2}</li>
                          :
                          <></>
                        }
                        {
                          (st.shortDescription3)
                          ?
                          <li>{st.shortDescription3}</li>
                          :
                          <></>
                        }
                        {
                            (st.shortDescription4)
                            ?
                            <li>{st.shortDescription4}</li>
                            :
                            <></>
                        }
                        
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>

          {/* Stripe Payment */}
          <div className="row m-2">
            <hr />
            <h2>Payment</h2>
            {/* | John Doe | johndoe@gmail.com | 4000001240000000 | */}
            <div className="card-body">
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm subscription={subscription}
                  handlePaymentSuccess={handlePaymentSuccess}
                  handleSubscriptionChange={handleSubscriptionChange} />
              </Elements>
            </div>
          </div>
        
        </div>
      }
    </div>
    
  )
}

export default Account;