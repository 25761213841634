import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';

import inMemoryTokens from '../../helpers/inMemoryTokens';

const SignIn = (props) => {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors} } = useForm();

  const onSignIn = async (data) => {
    try {
      const url = `${cnst.apiBaseUrl()}accounts/authenticate`;
      const result = await trackPromise(axios.post(url, data));
      const credentials = result.data;

      if (credentials.id) {
        inMemoryTokens.setToken(credentials.jwtToken);
        inMemoryTokens.setUser(credentials.email);
        inMemoryTokens.setUserId(credentials.id);
        history.push("/admin");
      }
    } catch (e) {
      const msg = e.response.data;
      Swal.fire({
        icon: 'error',
        title: cnst.applicationName,
        text: msg
      });
    }
  };

  return (
    <div className="container mt-5 animate__animated animate__backInUp">
      
      <div className="card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="card-header text-center">
          <p className="h3 my-4">Sign In to your DingoDeals Account</p>
        </div>
        
        <div className="card-body">

          <form className="text-center border border-light p-5" 
            onSubmit={handleSubmit(onSignIn)} 
            autoComplete="off">  

            <div className="mb-3 row">
              <label htmlFor="inputEmail" 
                className="col-md-4 col-sm-12 col-form-label">
                Username
              </label>
              <div className="col-md-8 col-sm-12">
                <input type="text" 
                  defaultValue=""
                  id="inputEmail"
                  name="email" 
                  className="form-control" 
                  placeholder="Email"
                  {...register('email', 
                    {
                      required: true, 
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Username should be in an email format."
                      }
                    })
                  } 
                />
              </div>

              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.email?.type === "required" && <span className="text-danger fs-6 fw-bold">Email is required</span>}
                {errors.email?.type === "pattern" && <span className="text-danger fs-6 fw-bold">{errors.email.message}</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputPassword" 
                className="col-md-4 col-form-label">
                Password
              </label>
              <div className="col-md-8">
                <input type="password" 
                  id="inputPassword"
                  name="password" 
                  className="form-control" 
                  placeholder="Password"
                  {...register('password', { required: true })}
                />
              </div>

              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.password?.type === "required" && <span className="text-danger fs-6 fw-bold">Password is required</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-md-4 col-form-label"></label>
              <button id="btnSubmit" 
                className="btn btn-primary btn-lg col-xl-3 col-lg-4 col-md-6 mx-2" 
                type="submit" >
                Login
              </button>
            </div>
          </form>
        </div>

        <div className="card-footer text-muted">
          <p className="text-center small">
            Don't have an account? <Link to="/signup">Click here to Register</Link>
          </p>
          <p className="text-center small">
            Registered but forgot password? <Link to="/forgotpassword">Click here to Recover Account</Link>
          </p>
        </div>

      </div>
    </div>
  )
}

export default SignIn;