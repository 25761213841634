// import React, { useEffect, useState } from 'react';
import React from 'react';
import * as cnst from '../../constants';

const DealCard = (
  {
    title,
    subtitle,
    shortNote,
    description,
    price,
    originalPrice,
    startDate,
    endDate,
    restaurantName,
    branchName,
    daysAvailable,
    active,
    imageFile,
    cardEditButtonClick
  }) => {
  const d = new Date();
  const t = d.getTime();
  const sourceImageFile = (imageFile) 
    ? `${cnst.dealImageBaseUrl()}${imageFile}?t=${t}` 
    : `${cnst.dealImageBaseUrl()}placeholder.jpg`; 
    
  return (
    <div className="card rounded-lg">
      <div className="card-header">
        <div className="row m-1">
          <div className="col-8">
            <h4 className="card-title">{title}</h4>
          </div>
          <div className="col-4 text-end">
            <h4>{cnst.moneyFormat(price)}</h4>
            <span className="text-danger"><del>{cnst.moneyFormat(originalPrice)}</del></span>
          </div>
        </div>
      </div>
      
      <div className="card-body">

        <img className="card-img-top border" src={sourceImageFile} alt=""></img>
        <hr />
        <div className="row mt-1">
          <div className="col-4 fs-6 fw-lighter text-end">
            Subtitle
          </div>
          <div className="col-8">
            {subtitle}
          </div>
        </div>
        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Short Note
          </div>
          <div className="col-8">
            {shortNote}
          </div>
        </div>
        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Description
          </div>
          <div className="col-8">
            {description}
          </div>
        </div>
        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Restaurant
          </div>
          <div className="col-8 fs-6">
            {restaurantName}
          </div>
        </div>
        
        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Branch
          </div>
          <div className="col-8 fs-6">
            {branchName}
          </div>
        </div>

        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Days
          </div>
          <div className="col-8 fs-6">
            {daysAvailable}
          </div>
        </div>

        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Deal Available
          </div>
          <div className="col-8 fs-6">
            {cnst.dateFormat(startDate)} - {cnst.dateFormat(endDate)}
          </div>
        </div>

        <div className="row">
          <div className="col-4 fs-6 fw-lighter text-end">
            Deal Active
          </div>
          <div className="col-8 fs-6">
            {
              (active)
              ?
              <i className="bi bi-check2-square"></i>
              :
              <i className="bi bi-square"></i>
            }
          </div>
        </div>
      </div>

      <div className="card-footer">
        <div className="d-grid">
          <button className="btn btn-info btn-block mx-2" 
            onClick={cardEditButtonClick}>
            Edit Deal
          </button>
        </div>
      </div>
    </div>
  )
};

export default DealCard;