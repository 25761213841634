import React, {useEffect, useRef} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as cnst from '../../constants';
import Swal from 'sweetalert2';
import './styles.css';

const ResetPassword = (props) => {
  const history = useHistory();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const password = useRef({});
  password.current = watch("password", "")

  let {resettoken} = useParams();

  useEffect(() => {

  }, [resettoken])

  const onResetPasswordSubmit = async (data) => {
    data.token = resettoken;
    
    try {
      const url = `${cnst.apiBaseUrl()}accounts/reset-password`;
      const result = await trackPromise(axios.post(url, data));
      Swal.fire({
        icon: 'success',
        title: 'DingoDeals',
        text: result.data.message,
        confirmButtonText: `Ok`,
        footer: 'You will now be directed to the login page.',
      }).then((res) => {
        history.push("/signin");
      });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: cnst.applicationName,
        text: e.response.data,
        footer: `Click Yes to go to the forgot password page. Cancel to try again.`,
        confirmButtonText: `Yes`,
        denyButtonText: `Cancel`,
        showDenyButton: true
      }).then((resp) => {
        if (resp.isConfirmed) {
          history.push("/forgotpassword");
        }
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="card col-md-6 offset-md-3">
        
        <div className="card-header text-center">
          <p className="h3 my-2">Reset Password</p>
        </div>

        <div className="card-body">

          <form className="text-center border border-light p-5" 
            onSubmit={handleSubmit(onResetPasswordSubmit)} 
            autoComplete="off">
            
            <div className="mb-3 row">
              <label htmlFor="inputPassword" 
                className="col-md-4 col-form-label">
                New Password
              </label>
              <div className="col-md-8">
                <input type="password" 
                  id="inputPassword"
                  name="password" 
                  className="form-control" 
                  placeholder="Password"
                  {...register('password', {
                    required: "Please specify a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters"
                    }
                  })}
                />
              </div>
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.password && <span className="text-danger fs-6 fw-bold">{ errors.password.message }</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputConfirmPassword" 
                className="col-md-4 col-form-label">
                Confirm New Password
              </label>
              <div className="col-md-8">
                <input type="password" 
                  id="inputConfirmPassword"
                  name="confirmPassword" 
                  className="form-control" 
                  placeholder="Confirm Password"
                  {...register('confirmPassword', { 
                    validate: value => value === password.current || "Passwords don't match" 
                  })} 
                />
              </div>
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.confirmPassword && <span className="text-danger fs-6 fw-bold">{ errors.confirmPassword.message }</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-md-4 col-form-label"></label>
              <button id="btnSubmit" 
                className="btn btn-primary btn-lg col-md-3 mx-2" 
                type="submit" >
                Submit
              </button>
            </div>


          </form>
        </div>

      </div>
    </div>
  )
};

export default ResetPassword;