import React from 'react';
import './styles.css';

const Unauthorized = (props) => {
  return (
    <>
      <div className="gandalf">
        
        <div className="fireball"></div>

        <div className="skirt"></div>
        
        <div className="sleeves"></div>
        
        <div className="shoulders">
          <div className="hand left"></div>
          <div className="hand right"></div>
        </div>
        
        <div className="head">
          <div className="hair"></div>
          <div className="beard"></div>
        </div>
      </div>
      
      <div className="message text-center">
        <h1>403 - You Shall Not Pass</h1>
        <p>
          Authorized Users Only!
        </p>
      </div>
    </>
  )
};

export default Unauthorized;