import React from 'react';
import { NavLink } from "react-router-dom";
import inMemoryTokens from '../../helpers/inMemoryTokens';

import './styles.css';

const Home = (props) => {
  const currentUser = inMemoryTokens.getUser();
  
  return (
    <div>
      <div className="main-section">

      <section className="h-100">
        <header className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="d-flex flex-column">
              <h1 className="text align-self-center p-2 display-3 text-white">Locally Owned Restaurant Deals</h1>
              <h4 className="text align-self-center p-2 display-5 text-white">#SupportLocal</h4>
              {/* <button className="btn btn-lg btn-block btn-outline-light align-self-center" type="button" name="button" click={() => onClick()}>Register Your Business</button> */}

              <div className="flex-row text-center">
                {
                  (currentUser === null) 
                  ?
                  <>
                    <NavLink exact={true} className="btn btn-lg btn-block btn-outline-light align-self-center mx-2 my-2" to='/signup'>Register your Business</NavLink>
                    <NavLink exact={true} className="btn btn-lg btn-block btn-outline-light align-self-center mx-2 my-2" to='/signin'>Login to your Account</NavLink>
                  </>
                  :
                  <>
                    <NavLink exact={true} className="btn btn-lg btn-block btn-outline-light align-self-center mx-2 my-2" to='/admin'>Go to Admin Page</NavLink>
                  </>
                }
                
              </div>
            </div>

            
          </div>
        </header>
      </section>

      </div>
    </div>
  )
};

export default Home;




/*
<div className="container-fluid">
      <div className="fill">
        <div className="row">
            <div className="col-md-4 col-sm-12 p-2">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Search</h5>
                        <hr />
                        <p className="card-text">
                            View and search the inventory for items.
                        </p>
                        <NavLink className="btn btn-primary" to='/inventory'>
                            <span className="fa fa-arrow-circle-right" aria-hidden="true" />
                            &nbsp;
                            Proceed
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-sm-12 p-2">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">My Checkouts</h5>
                        <hr />
                        <p className="card-text">
                            View items you have currently borrowed.
                        </p>
                        <NavLink className="btn btn-primary" to='/my'>
                            <span className="fa fa-list-alt" aria-hidden="true" />
                            &nbsp;
                            My List
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-sm-12 p-2">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">History</h5>
                        <hr />
                        <p className="card-text">
                            View your borrowing history.
                        </p>
                        <NavLink className="btn btn-primary" to='/history'>
                            <span className="fa fa-file-text-o" aria-hidden="true" />
                            &nbsp;
                            History
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
          
      </div>
    </div>

*/


// export class Home extends Component {
//   static displayName = Home.name;

//   render () {
//     return (
//       <div>
//         <h1>Hello, world!</h1>
//         <p>Welcome to your new single-page application, built with:</p>
//         <ul>
//           <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
//           <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
//           <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
//         </ul>
//         <p>To help you get started, we have also set up:</p>
//         <ul>
//           <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
//           <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
//           <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
//         </ul>
//         <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
//       </div>
//     );
//   }
// }
