import { format, parseISO } from 'date-fns';

const w = window.location
const protocol = w.protocol
const host = w.host
const subDir = document.getElementsByTagName('base')[0].getAttribute('href')

export const googleApiKey = "AIzaSyBW7sgpLew3ajtQRhhidVeIiBf9uS_JKDk";

export const applicationName = "Dingo Deals";

export const apiBaseUrl = () => {
  return `${protocol}//${host}${subDir}api/`
}

export const logoBaseUrl = () => {
  return `${protocol}//${host}${subDir}Images/Logo/`
}

export const menuImageBaseUrl = () => {
  return `${protocol}//${host}${subDir}Images/Menu/`
}

export const dealImageBaseUrl = () => {
  return `${protocol}//${host}${subDir}Images/Deals/`
}

export const moneyFormat = (price, sign = '$') => {
  const pieces = parseFloat(price).toFixed(2).split('')
  let ii = pieces.length - 3
  while ((ii-=3) > 0) {
    pieces.splice(ii, 0, ',')
  }
  return `${sign} ${pieces.join('')}`
}

export const dateFormat = (dateItem, dateFormat = 'MMM dd, yyyy') => {
  // Available Date Formats
  // MMM dd, yyyy
  return (dateItem) ? format(parseISO(dateItem), dateFormat) : '';
}

// ERROR MESSAGES
const plsTryAgain = 'Please try again';
export const errUploadDealImage = `Error uploading image for this deal. ${plsTryAgain}`;
