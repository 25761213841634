import React from 'react';
import Navigation from '../Navigation';

import './styles.css';
// import * as cnst from '../../constants';

const Layout = (props) => {
  const currUser = props.currentUser;
  
  return (
    <div>
      {(currUser) &&
          <>
              <Navigation currentUser={currUser} />
              
              <div>
                  {props.children}
              </div>
              
              {/* <Footer /> */}
          </>
      }
    </div>
  );
};

export default Layout;