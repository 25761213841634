import React, {useState} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import * as cnst from '../../constants';
import './styles.css';

const SignUp = (props) => {
  const history = useHistory();
  const { register, handleSubmit, formState: {errors} } = useForm();

  const [signupForm, setSignupForm] = useState({});

  const onSignUpClick = async (data) => {
    data.role = "BUSINESS";
    setSignupForm(data);

    try {
      const url = `${cnst.apiBaseUrl()}accounts/register`;
      const result = await trackPromise(axios.post(url, data));
      if (result.data) {
        Swal.fire({
          icon: 'success',
          title: 'DingoDeals',
          text: 'Registration successful.',
          footer: 'Click Ok to be redirected to the account verification page.',
          confirmButtonText: `Ok`,
        }).then((res) => {
          // Navigate to verification page.
          history.push("/verifyaccount");
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'DingoDeals',
          text: 'Registration error encountered. Please try again.',
          footer: '',
          confirmButtonText: `Ok`
        })
      }
    } catch (e) {
      console.log('error response', e.response);
      Swal.fire({
        icon: 'error',
        title: 'DingoDeals',
        text: 'An unknown error has occurred. Please contact support.',
        footer: '',
        confirmButtonText: `Ok`
      })
    }
  }


  return (
    <div className="container mt-5">
      <div className="card col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">

        <div className="card-header text-center">
          <p className="h3 my-2">Sign Up with DingoDeals</p>
          <p className="h6 mb-2">Register your business with Dingo Deals</p>
        </div>

        <div className="card-body">
          
          <form className="text-center border border-light p-5" onSubmit={handleSubmit(onSignUpClick)} autoComplete="off">
            <div className="mb-3 row">
              <label htmlFor="inputEmail" className="col-md-4 col-form-label">Email address</label>
              <div className="col-md-8">
                <input type="text" 
                  id="inputEmail"
                  name="email" 
                  className="form-control" 
                  placeholder="Email"
                  defaultValue={signupForm.email}
                  {...register('email', {required: true, 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email format."
                  }})} 
                />
              </div>
              
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.email?.type === "required" && <span className="text-danger fs-6 fw-bold">Email is required</span>}
                {errors.email?.type === "pattern" && <span className="text-danger fs-6 fw-bold">{errors.email.message}</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputPassword" className="col-md-4 col-form-label">Password</label>
              <div className="col-md-8">
                <input type="password" 
                  id="inputPassword"
                  name="password"
                  className="form-control" 
                  placeholder="Password"
                  defaultValue={signupForm.password}
                  {...register('password', {required: true})} 
                />
              </div>

              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.password?.type === "required" && <span className="text-danger fs-6 fw-bold">Password is required</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputPassword" 
                className="col-md-4 col-form-label">
                Confirm Password
              </label>
              <div className="col-md-8">
                <input type="password" 
                  id="inputConfirmPassword"
                  name="confirmPassword"
                  className="form-control" 
                  placeholder="Confirm Password"
                  defaultValue={signupForm.confirmPassword}
                  {...register('confirmPassword', {required: true})} 
                />
              </div>
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.confirmPassword?.type === "required" && <span className="text-danger fs-6 fw-bold">Confirm password is required</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputConfirmTerms" className="col-md-4 col-form-label">Agree to <Link to="/terms" target="_blank">Terms</Link>?</label>
              <div className="col-md-8 text-start">
                <input type="checkbox" 
                  className="form-check-input big-checkbox" 
                  name="acceptTerms"
                  id="cbxAcceptTerms"
                  value="true"
                  {...register('acceptTerms', {required: true})} 
                />
              </div>
              <label className="col-md-4 col-form-label"></label>
              <div className="col-md-8 text-start">
                {errors.acceptTerms?.type === "required" && <span className="text-danger fs-6 fw-bold">Terms is required to be accepted</span>}
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-md-4 col-form-label"></label>
              <button id="btnSave" 
                className="btn btn-primary btn-lg col-xl-3 col-lg-4 col-md-6 mx-2" 
                type="submit" >
                Register
              </button>
            </div>
          </form>
        </div>

        <div className="card-footer text-muted">
          <p className="text-center small">
            Already have an account? Click here to <Link to="/signin">Sign In</Link>
          </p>

          <p className="text-center small">
            Registered but did not get verification code? Click here to <Link to="/sendverification">Get Verification</Link>
          </p>
        </div>
      </div>
    
    </div>
  )
}

export default SignUp;