import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import './styles.css';

/* 
For the Loader, we can use different types such as Bars, Rings, RevolvingDot, Oval, Puff, Circles, ThreeDots 
https://github.com/mhnpd/react-loader-spinner
*/

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker()

    return (
        promiseInProgress &&
        (
            <div className="spinner">
                <Loader type="ThreeDots" height={100} width={100} color="#00BFFF" />
            </div>
        )
    )
}