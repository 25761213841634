import React, {useState} from 'react';

const Featured = (props) => {
  const [featuredDeals, setFeaturedDeals] = useState([]);

  return (
    <div>
      Featured
    </div>
  )
}

export default Featured;